import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RouteOption } from './types'

interface RouterProps {
  routes: { [key: string]: RouteOption }
  fallback?: React.ReactNode
}

const buildElement = (opt: RouteOption) => {
  const Component = opt.component;
  if (opt.layout) {
    const Layout = opt.layout;
    return <Layout><Component/></Layout>
  } else {
    return <Component/>;
  }
}

const Routers: React.FC<RouterProps> = ({ routes, fallback }) => {
  
  return (
    <React.Suspense fallback={fallback ?? null}>
      <BrowserRouter>
        <Routes>
        {routes && Object.keys(routes).map((path, index) => (
            <Route key={index} path={path} element={buildElement(routes[path])} /> 
        ))}
        </Routes>
      </BrowserRouter>        
    </React.Suspense>
  )
}

export default Routers
