
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom"
import { MessageTypeEnum } from "../../models/post";
import PostDetail from './post/PostDetail'
import { Box, useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import OpenAppBox from "./post/OpenAppBox";
import { isWeixinBrowser } from "src/tools/utils/appUtil";
import { Base64 } from "js-base64";

/**
 * App里点击分享，分享成功后打开的页面为此页面，可以显示Post详情，评论和Live broadcast详情，只能看，不可以评论点赞互动
 * 用户从网站首页自行点击无法进入此页面
 */
const MessageDetails: React.FC = () => {
  const LIST_BOX_ID = 'comment-list-box';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const paramVersion = params.get("version");
  const paramMessageId = params.get("id");
  const messageTypeStr = params.get('message_type');
  const messageType = messageTypeStr ? parseInt(messageTypeStr) : MessageTypeEnum.Message;
  const messageId = useMemo(() => {
    if (!paramMessageId) {
      return "";
    }
    if (paramVersion) {
      return Base64.decode(paramMessageId);
    } else {
      return decodeURIComponent(paramMessageId);
    }
  }, [paramMessageId, paramVersion])

  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [isShowOpenApp, setIsShowOpenApp] = useState<boolean>(true);

  const getPageContent = () => {
    if (messageType === MessageTypeEnum.Message) {
      return <PostDetail messageId={messageId || ""} loadMoreCount={loadMoreCount} />
      // } else if (messageType === MessageTypeEnum.LiveBroadcast) {
      //   return <LiveBroadcastDetail liveId={messageId||""} />
    } else return (
      <>
        Uknown message type
      </>
    )
  }

  const closeTip = () => {
    setIsShowOpenApp(false)
  }


  const handleListScroll = () => {
    const listBox = document.getElementById(LIST_BOX_ID);
    if (!listBox) {
      return;
    }
    const scrollHeight = listBox.scrollHeight;
    const scrollTop = listBox.scrollTop;
    const windowHeight = listBox.clientHeight;
    if ((scrollHeight - scrollTop - windowHeight < 200)) {
      // console.log('scroll bottom.....................')
      setLoadMoreCount(count => count + 1);
    }
  }

  return (
    <Box sx={{
      width: '100%',
      height: '100vh !important',
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'wrap',
      background: 'white',
      overflowY: 'auto',
    }}
      onScroll={handleListScroll}
      id={LIST_BOX_ID} >
      {/* 手机布局显示打开app的选项 */}
      {(isMobile && isShowOpenApp && !isWeixinBrowser) && <OpenAppBox onClickClose={closeTip} />}

      <Box sx={{
        maxWidth: '1400px',
        margin: '0 auto',
        textAlign: 'center',
        paddingBottom: '60px',
        height: '100%',
        width: '100%',
      }}
      >
        {getPageContent()}
      </Box>
    </Box>
  )
}

export default MessageDetails;