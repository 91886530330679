import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

export default class ProgressBar {
  static start() {
    return NProgress.start()
  }

  static done() {
    return NProgress.done()
  }
}
