import React from 'react';
import PaginationDot from './SliderPagenationDot';
import { Box } from '@mui/material';

interface PaginationProps {
    dots: number,
    selectedIndex: number,
    onChangeIndex:(index: number) => void,
}

const Pagination: React.FC<PaginationProps> = ({dots, selectedIndex, onChangeIndex, ...props}) => {
    
  return (
       <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {[...Array(dots)].map((value, i) => (
            <Box sx={{margin: '0 3px'}} key={i} >
              <PaginationDot active={i === selectedIndex} onClick={() => onChangeIndex(i)} />
            </Box>
          ))}
        </div>
    )
}

export default Pagination;