import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultLanguage } from '../../models/locale';
import { settings } from '../settings'
import { UserProfile } from '../../models/user';

const keys = settings.keys

interface AppState {
  credential?: UserProfile
  language: string
}

// load auth identity from local storage
function _loadState(): AppState {
  const appRaw = (typeof window !== 'undefined') ? localStorage.getItem(keys.app) : null
  if (appRaw !== null) {
    return JSON.parse(appRaw) as AppState
  }
  return {
    language: defaultLanguage
  }
}

const appSlice = createSlice({
  name: keys.app,
  initialState: _loadState(),
  reducers: {
    setIdentity(state, action: PayloadAction<UserProfile | undefined>) {
      state.credential = action.payload
      localStorage.setItem(keys.app, JSON.stringify(state))
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload
      localStorage.setItem(keys.app, JSON.stringify(state))
    },
    clearIdentity(state) {
      state = { language: defaultLanguage }
      localStorage.removeItem(keys.app)
    }
  }
})

export const { setIdentity, setLanguage, clearIdentity } = appSlice.actions
export default appSlice.reducer
