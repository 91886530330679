import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { openApp } from './openApp';
import { enqueueSnackbar } from 'notistack';

const WX_APP_OPEN_ID = "wx2f66418ce281c6be"; // 微信开放平台的app id

const WechatOpenAppBtn = () => {
    const wxRef = useRef(null)
    useEffect(() => {
        // @ts-ignore
        wxRef.current?.addEventListener('launch', function (e: any) {
            console.log('success');
        });
        // @ts-ignore
        wxRef.current?.addEventListener('error', function (e) {
            console.log('fail', e.detail);
            enqueueSnackbar('Please check if Mukbee app is installed', { variant: 'warning' });
            openApp();
        });
        // @ts-ignore
        wxRef.current?.addEventListener('ready', function (e) {
            console.log('ready', e.detail);
        });
    }, [])

    return (
        <Box sx={{ marginLeft: "10px", position: 'relative', width: "64px", height: "32px", }}>
            <Box sx={{
                width: "100%",
                height: "100%",
                background: "#EAF4FF",
            }} />
            {/*  @ts-ignore */}
            <wx-open-launch-app
                style={{ width: '100%', height: '100%', display: 'block', position: 'absolute', top: 0 }}
                ref={wxRef}
                appid={WX_APP_OPEN_ID}
                extinfo='fromWeChatBrowser'
            >
                <script type="text/wxtag-template">
                    <button style={{
                        padding: '7px 12px',
                        background: "FFCE00",
                        borderRadius: "16px",
                        color: "#FFFFFF",
                    }}
                    >Open</button>
                </script>
                {/*  @ts-ignore */}
            </wx-open-launch-app>
        </Box>
    )
}
export default WechatOpenAppBtn