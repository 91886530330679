
// import * as crypto from 'crypto';

export const isNumber = (v:any) => typeof v === 'number'

export const isNull = (v: any) => v === undefined || v === null
  

export const getDisplayUserName = (userName: string, userId: string) => {
    if (!userName) {
        return userId;
    } else if (userName === userId) {
        return userName.trim();
    } else if (userName.includes("##")) {
        return userName.replace("##", " ").trim();
    } else {
        return userName.trim();
    }
}

/**
 * get description about how long ago
 * @param timestamp seconds
 * @returns 
 */
export const howLongAgo = (timestamp: number) => {
    const current = new Date().getTime() / 1000;
    const ago = current - timestamp;
    if (ago < 10) {
        return "just now"
    }
    if (ago < 60) {
        return Math.floor(ago) + " seconds ago";
    }
    if (ago < 60 * 60) {
        const num = Math.floor(ago/60);
        return num === 1 ? "1 minute ago" : num + " minutes ago";
    }
    if (ago < 60 * 60 * 24) {
        const num = Math.floor(ago / 60 / 60);
        return num === 1 ? "1 hour ago" : num + " hours ago ";
    }
    if (ago < 60 * 60 * 24 * 7) {
        const num = Math.floor(ago / 60 / 60 / 24);
        return num === 1 ? "1 day ago" : num + " days ago";
    }
    if (ago < 60 * 60 * 24 * 7 * 4) {
        const num = Math.floor(ago / 60 / 60 / 24 / 7);
        return num === 1 ? "1 week ago" : num + " weeks ago";
    }
    if (ago < 60 * 60 * 24 * 7 * 4 * 12) {
        const num = Math.floor(ago / 60 / 60 / 24 / 7 / 4);
        return num === 1 ? "1 month ago" : num + " months ago";
    }
    const num = Math.floor(ago / 60 / 60 / 24 / 7 / 4 / 12);
    return num === 1 ? "1 year ago" : num + " years ago";
}

export const getFormatDate = (timeMillionSeconds: number) => {
    var date=new Date(timeMillionSeconds);
    var year=date.getFullYear();
    var mon = date.getMonth()+1;
    var day = date.getDate();
    return year + '-' + mon + '-' + day;
  }

  export const isJSON = (str: string): boolean => {
    if (typeof str == 'string') {
        try {
            var obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            } else {
                return false;
            }

        } catch(e) {
            return false;
        }
    }
    return false
}

export const checkNumber = (str) => {
  var reg = /^[0-9]+.?[0-9]*$/;
  if (reg.test(str)) {
    return true;
  }
  return false;
}


export const getUrlQuery = (url: string): Record<string, string> => {
    const result: Record<string, string> = {};
    if (url.length > 0) {
        if (url[0] === '?') {
            url = url.substring(1);
            const urlList = url.split('&')
            urlList.forEach(item => {
                const equalIndex = item.indexOf('=')
                const key = item.substring(0, equalIndex);
                const value = item.substring(equalIndex + 1);

                // let list = item.split('=');
                result[key] = value;
            })
        }
    }
    return result;
}

export const getLegalFileName = (fileName: string) => {
    if (!fileName) {
        return "";
    }
    const index = fileName.lastIndexOf(".");
    if (index === -1) {
        return replaceIllegalCharecter(fileName);
    } else {
        const preName = fileName.substring(0, index);
        const ext = fileName.substr(index + 1);
        return replaceIllegalCharecter(preName) + "." + ext;
    }
}

const replaceIllegalCharecter = (str: string) => {
    return str ? str.replace(/[^a-zA-Z\d\u4e00-\u9fa5_-]/g, "_") : "";
}

export const formatFileSize = (bytes: number, decimalPoint = 2) => {
    if (bytes === 0) return "0 Bytes";
    let k = 1000,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
    );
}

export const extractPlainTextFromRichText = (htmlString: string|undefined|null) => {
    return htmlString ? htmlString.replace(/<[^>]+>/g, '') : '';
}

export const getNumberDesc = (num: number) => {
    if (num > 10 * 1000) {
        return '1W+';
    } else if (num > 1000) {
        return '1K+';
    } else {
        return num || 0;
    }
}

export const emailCheck = (email: string): boolean => {
    const regEmail = /^[a-zA-Z\d]+([-_.][a-zA-Z\d]+)*@[a-zA-Z-\d]+\.[a-zA-Z\d]{2,4}$/;
    return regEmail.test(email);
}

export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
