import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';
import { settings } from '../../service/settings';

export const axiosApi = (): AxiosInstance =>
  Axios.create({
    baseURL: process.env.REACT_APP_API_URL,//"https://rest-test.dev.veeko.com",
    headers: {
      ...axiosDefaultConfig().headers,
    },
  });
  const token = localStorage.getItem(settings.keys.accessToken);

const axiosDefaultConfig = (): AxiosRequestConfig =>
  token ? {headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
  }} : {headers: {'Content-Type': 'application/json',}};

export const errorNotificationWith = ({
  message,
  description,
}: any): void => {
  console.error('API error: ' + message + ', ' + description);
};

export const checkResponse =(response?: AxiosResponse): boolean => {
  return (
    !!response && 
    !!response.data &&
    response.status === 200 &&
    (response.data.result.result_code === 201000 || response.data.result.code === 201000 )
  );
};

function handleAxiosErrors<T>(
  e: AxiosError,
  error?: (er: AxiosError<T>) => AxiosResponse<T> | void,
): AxiosResponse<T> | void {
  errorNotificationWith({
    message: e?.message,
  });
  return undefined;
}

interface ApiWrapper<T = unknown> {
  success: () => Promise<AxiosResponse<T> | void>;
  error?: (e: AxiosError<T>) => AxiosResponse<T> | void;
}

const apiWrapperInternal = async <T = unknown>({
  success,
  error,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  try {
    // store.dispatch(showLoader());
    return await success();
  } catch (e: any) {
    if (e.isAxiosError) {
      return handleAxiosErrors(e, error);
    }
    errorNotificationWith({
      message: e?.response?.data?.message || e.message,
    });
    throw e.response;
  } finally {
    // store.dispatch(hideLoader());
  }
};

// let timeoutId: NodeJS.Timeout;
export const apiWrapper = async <T = unknown>({
  success,
  error,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  // if (timeoutId) {
  //   clearTimeout(timeoutId);
  // }
  // timeoutId = setTimeout(() => logoutUser(), LOGOUT_TIMEOUT);

  return apiWrapperInternal<T>({
    success,
    error,
  });
};
