
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
import { Box, CircularProgress, Dialog, DialogContent, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';

interface PropsForm {
  showDialog: boolean;
  pictureUrlList: Array<any>;
  photoIndex: number;
  onClose: () => void;
}

const FullscreenDialogPictureSlider: React.FC<PropsForm> = (props) => {
  const { showDialog, onClose, pictureUrlList, photoIndex } = props;
  // const classes = useStyles();
  const theme = useTheme();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAction2, setIsAction2] = React.useState(false)
  const [isAction, setIsAction] = React.useState(false)
  const [isShowPreviousBtn, setIsShowPreviousBtn] = React.useState<boolean>(false);
  const [isShowNextBtn, setIsShowNextBtn] = React.useState<boolean>(false);
  const [Index, setIndex] = React.useState(0);
  const [newPictureUrlList, setNewPictureUrlList] = React.useState<any[]>([])
  const [isShowLoading, setisShowLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (pictureUrlList) {
      formatImageSize()
    }
  }, [pictureUrlList])

  React.useEffect(() => {
    setIndex(photoIndex)
  }, [photoIndex])

  React.useEffect(() => {
    if (Index === 0) {
      setIsShowNextBtn(pictureUrlList.length > 1)
      setIsShowPreviousBtn(false)
    } else if (Index === pictureUrlList.length - 1) {
      setIsShowNextBtn(false)
      setIsShowPreviousBtn(true)
    } else {
      setIsShowNextBtn(true)
      setIsShowPreviousBtn(true)
    }
  }, [Index, pictureUrlList])

  const formatImageSize = async () => {
    setisShowLoading(true)
    if (pictureUrlList && pictureUrlList.length > 0) {
      // 判断图片是横屏还是竖屏
      const newList:any[] = [];
      for (const item of pictureUrlList) {
        const img = new Image();
        const promise = new Promise((resolve, reject) => {
          img.onload = function () {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
            if (aspectRatio > 1) {
              // 宽度比高度大，认为是横屏图片
              resolve({ url: item, size: true });
            } else {
              // 宽度比高度小，认为是竖屏图片
              resolve({ url: item, size: false });
            }
          };
          img.onerror = function () {
            reject(new Error("Failed to load image: " + item));
          };
        });
        img.src = item;
        newList.push(await promise);
        setisShowLoading(false)
      }
      setNewPictureUrlList(newList);
    } else {
      setNewPictureUrlList([]);
    }

  };

  const handlePrev = () => {
    if (Index <= 0) {
      setIndex(Index)
      return;
    } else {
      setIndex((Index + pictureUrlList.length - 1) % pictureUrlList.length);
      setIsShowNextBtn(true)
    }
  };

  const handleNext = () => {
    if (Index >= pictureUrlList.length - 1) {
      setIndex(Index)
    } else {
      setIndex((Index + 1) % pictureUrlList.length);
    }
  };

  const handleSwipe = (eventData: any) => {
    // 移动端滑动
    const { dir } = eventData;
    if (dir === 'Left') {
      handleNext()
    } else if (dir === 'Right') {
      handlePrev()
    }
  };

  const swipeHandlers = useSwipeable({ onSwiped: handleSwipe });

  function uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
    );
  }

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={showDialog}
        fullWidth={true}
        sx={{
          // "& .MuiDialogContent-root:first-child": {
          //   paddingTop: "0"
          // },
          "& .MuiDialogContent-root": {
            padding: "0px"
          },
          "& .MuiDialog-paperScrollPaper": {
            width: "100%",
            maxHeight: "100%"
          },
          "& .MuiDialog-paper": {
            margin: "0px",
          },
          "& .MuiDialog-paperWidthLg": {
            maxWidth: "100%"
          },
          "& .MuiDialog-container": {
    
          },
          "& .MuiPaper-rounded": {
            borderRadius: 0
          },
          "& .MuiPaper-root": {
            background: "none",
            height: "100vh"
          },
          [theme.breakpoints.down('sm')]: {
            // "& .MuiDialogContent-root:first-child": {
            //   paddingTop: "0"
            // },
            "& .MuiDialogContent-root": {
              padding: "0",
            },
            "& .MuiDialog-paperScrollPaper": {
              // width: "100%"
            },
            "& .MuiDialog-paper": {
              margin: "0"
            },
            "& .MuiDialog-paperWidthLg": {
              maxWidth: "100%"
            },
            "& .MuiBackdrop-root": {
              background: "black"
            },
            "& .MuiPaper-root": {
              height: "100%"
            },
          }
        }}
        // onClose={onClose}
      >

        <div style={{
          // background: "black",
          color: "white",
          textAlign: "center",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          zIndex: 999,
          height: "30px",
          width: "100%",
          position: 'absolute',
        }}>
          <div style={{width: "50px"}}> <CloseIcon style={{cursor: 'pointer'}} onClick={onClose} /> </div>
          <div style={{width: "50px"}}>{Index + 1}/{pictureUrlList.length}</div>
          <div style={{width: "50px"}}></div>
        </div>
        <DialogContent style={{
          fontSize: '16px',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "black",
        }}>
          {isMobile ? "" :
            <button
              disabled={!isShowPreviousBtn}
              onMouseOut={() => setIsAction(false)}
              onMouseOver={() => setIsAction(true)}
              onClick={handlePrev}
              style={{
                background: "none",
                transform: "rotate(180deg)",
                width: "150px",
                height: "60px",
                border: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CSSTransition
                in={isAction}
                addEndListener={() => { }}
                classNames="alert"
                style={{
                  display: isShowPreviousBtn ? "" : "none"
                }}
              >
                <ArrowForwardIosIcon sx={{ 
                  fontSize: "50px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  textAlign: "center"
                }} />
              </CSSTransition>
            </button>}
          {/* 外层可视区域 */}
          <div
            // className="carousel"
            // ref={containerRef}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
            {...swipeHandlers}
          >
            {/* 图片数组容器 */}
            <div
              // className="carousel-images"
              style={{ 
                transform: `translateX(-${(Index) * 100}%)`,
                display: 'flex',
                position: 'absolute',
                width: '100%',
                height: '100%',
                transition: 'transform 0.3s ease-in-out',
              }}>
              {newPictureUrlList?.map((item) => (
                // 每一张图片
                <div
                  // onClick={onClose}
                  key={uuid()}
                  // className={"carousel-image"}
                  style={{
                    backgroundImage: `url(${item.url})`,
                    // 竖屏pc
                    backgroundSize: !item.size && !isMobile ? "auto 100%" : "100%",
                    flexShrink: 0,
                    width: '100%',
                    height: '100%',
                    backgroundPosition: 'center',
                    /* background-size: auto 100%, */
                    backgroundRepeat: 'no-repeat',
                  }}>

                </div>
              ))}
            </div>
          </div>
          {isMobile ? "" : <button
            disabled={!isShowNextBtn}
            onMouseOut={() => setIsAction2(false)}
            onMouseOver={() => setIsAction2(true)}
            onClick={handleNext}
            style={{
              background: "none",
              transform: "rotate(180deg)",
              width: "150px",
              height: "60px",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CSSTransition
              in={isAction2}
              addEndListener={() => { }}
              classNames="alert"
              style={{
                display: isShowNextBtn ? "" : "none"
              }}
            >
              <ArrowBackIosNewIcon sx={{ 
                  fontSize: "50px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  textAlign: "center"
                }} />
            </CSSTransition>
          </button>}
        </DialogContent>
        <Box sx={{
          position: "absolute",
          left: "46%",
          top: "46%",
          display: isShowLoading ? "" : "none",
          zIndex: "999"
        }}>
          <CircularProgress />
        </Box>
      </Dialog>
    </>
  )
}
export default FullscreenDialogPictureSlider;