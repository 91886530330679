export interface List<T> {
  items: T[]
}

export interface Response<T> {
  result: {
    code: number
    message: string
  }
  result_code: number
  message: string
  data: T
  session_id?: string
}

export interface Result<T> {
  result: T
  resultCode: number
  message?: string
  isFailed: boolean
  session_id?: string
}

export class Value<T> implements Result<T> {
  result: T
  resultCode: number
  message?: string
  isFailed: boolean
  session_id?: string

  constructor(res: Response<T>, dataName?: string) {
    this.isFailed = false
    this.result = dataName === 'res' ? res : (dataName ? res[dataName] : res.data)
    this.resultCode = res.result ? res.result.code : res.result_code
    if (Boolean(res.result_code) && res.result_code !== 201000) {
      this.message = res.message
      this.isFailed = true
    } else if (Boolean(res.result) && res.result.code !== 201000) {
      this.message = res.result.message
      this.isFailed = true
    } else {
      if (res.session_id) {
        this.session_id = res.session_id
      }
    } 
  }
}

export interface Error {
  status: number | string
  data: {
    code: string
    message: string
  }
  error: string
}
