import * as React from 'react';
import { ReactElement } from 'react';

interface SymbolSvg {
  id: string;
  viewBox: string;
  source: string;
  width: string;
  height: string;
}

interface SvgSpriteProps {
  symbols: SymbolSvg[];
}

export default function SvgSprite({ symbols }: SvgSpriteProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
      {symbols.map((symbol) => (
        <symbol
          key={symbol.id}
          width={symbol.width}
          height={symbol.height}
          id={symbol.id}
          viewBox={symbol.viewBox}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: symbol.source }}
        />
      ))}
    </svg>
  );
}