
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
import { Box, CircularProgress, useTheme } from '@mui/material';
import IcoPre from '../../../images/location/ico-pre-96.png'
import IcoNext from '../../../images/location/ico-next-96.png'
import { SvgComponent } from '../../svgComponent';
import Pagination from "../../slider_pagination/index";

interface PropsForm {
  pictureUrlList: string[];
  photoIndex: number;
}

const PhotoSlider: React.FC<PropsForm> = (props) => {
  const { pictureUrlList, photoIndex } = props;
  // const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAction2, setIsAction2] = React.useState(false)
  const [isAction, setIsAction] = React.useState(false)
  const [isShowPreviousBtn, setIsShowPreviousBtn] = React.useState<boolean>(false);
  const [isShowNextBtn, setIsShowNextBtn] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState(photoIndex);
  const [isShowLoading, setisShowLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    setIndex(photoIndex)
  }, [photoIndex])

  React.useEffect(() => {
    if (index === 0) {
      setIsShowNextBtn(pictureUrlList.length > 1)
      setIsShowPreviousBtn(false)
    } else if (index === pictureUrlList.length - 1) {
      setIsShowNextBtn(false)
      setIsShowPreviousBtn(true)
    } else {
      setIsShowNextBtn(true)
      setIsShowPreviousBtn(true)
    }
  }, [index, pictureUrlList])

  // const formatImageSize = async () => {
  //   setisShowLoading(true)
  //   if (pictureUrlList && pictureUrlList.length > 0) {
  //     // 判断图片是横屏还是竖屏
  //     const newList:any[] = [];
  //     for (const item of pictureUrlList) {
  //       const img = new Image();
  //       const promise = new Promise((resolve, reject) => {
  //         img.onload = function () {
  //           const width = img.width;
  //           const height = img.height;
  //           const aspectRatio = width / height;
  //           if (aspectRatio > 1) {
  //             // 宽度比高度大，认为是横屏图片
  //             resolve({ url: item, size: true });
  //           } else {
  //             // 宽度比高度小，认为是竖屏图片
  //             resolve({ url: item, size: false });
  //           }
  //         };
  //         img.onerror = function () {
  //           reject(new Error("Failed to load image: " + item));
  //         };
  //       });
  //       img.src = item;
  //       newList.push(await promise);
  //       setisShowLoading(false)
  //     }
  //     setNewPictureUrlList(newList);
  //   } else {
  //     setNewPictureUrlList([]);
  //   }

  // };

  const handlePrev = () => {
    if (index <= 0) {
      setIndex(index)
      return;
    } else {
      setIndex((index + pictureUrlList.length - 1) % pictureUrlList.length);
      setIsShowNextBtn(true)
    }
  };

  const handleNext = () => {
    if (index >= pictureUrlList.length - 1) {
      setIndex(index)
    } else {
      setIndex((index + 1) % pictureUrlList.length);
    }
  };

  const handleSwipe = (eventData: any) => {
    // 移动端滑动
    const { dir } = eventData;
    if (dir === 'Left') {
      handleNext()
    } else if (dir === 'Right') {
      handlePrev()
    }
  };

  const swipeHandlers = useSwipeable({ onSwiped: handleSwipe });

  function uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
    );
  }

  const handleChangeSwipeIndex = (index: number) => {
    setIndex(index)
  };

  return (
    <Box sx={{ width: '100%', height: '500px' }}>
      {isMobile ?
        <Box style={{
          position: 'absolute', right: '10px', top: "15px", width: "auto", padding: "5px 10px",
          background: "#707070", color: "white", borderRadius: '15px',
          textAlign: "center", fontSize: '12px', zIndex: 999
        }}>
          {index + 1}/{pictureUrlList.length}
        </Box>
        :
        <Box style={{
          position: 'absolute', width: '100%', top: "15px",
          color: "white", textAlign: "center", zIndex: 999, fontSize: '12px',
        }}>
          {index + 1}/{pictureUrlList.length}
        </Box>
      }
      <Box style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: '100%',
        height: '100%',
        position: 'relative',
      }}>
        {isMobile ? "" :
          <button
            disabled={!isShowPreviousBtn}
            onMouseOut={() => setIsAction(false)}
            onMouseOver={() => setIsAction(true)}
            onClick={handlePrev}
            style={{
              background: "none",
              transform: "rotate(180deg)",
              width: "150px",
              height: "60px",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CSSTransition
              in={isAction}
              addEndListener={() => { }}
              classNames="alert"
              style={{
                width: '48px', height: '48px', cursor: 'pointer',
                display: isShowPreviousBtn ? "" : "none"
              }}
            >
              <img alt="ico-pre" src={IcoPre} />
            </CSSTransition>
          </button>}
        {/* 外层可视区域 */}
        <div
          // className="carousel"
          // ref={containerRef}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          {...swipeHandlers}
        >
          {/* 图片数组容器 */}
          <div
            // className="carousel-images"
            style={{
              transform: `translateX(-${(index) * 100}%)`,
              display: 'flex',
              position: 'absolute',
              width: '100%',
              height: '100%',
              transition: 'transform 0.3s ease-in-out',
            }}>
            {pictureUrlList?.map((item) => (
              // 每一张图片
              <div
                // onClick={onClose}
                key={uuid()}
                // className={"carousel-image"}
                style={{
                  backgroundImage: `url(${item})`,
                  // 竖屏pc
                  backgroundSize: "contain",
                  flexShrink: 0,
                  width: '100%',
                  height: '100%',
                  backgroundPosition: 'center',
                  /* background-size: auto 100%, */
                  backgroundRepeat: 'no-repeat',
                }}>

              </div>
            ))}
          </div>
        </div>
        {isMobile ? "" : <button
          disabled={!isShowNextBtn}
          onMouseOut={() => setIsAction2(false)}
          onMouseOver={() => setIsAction2(true)}
          onClick={handleNext}
          style={{
            background: "none",
            transform: "rotate(180deg)",
            width: "150px",
            height: "60px",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CSSTransition
            in={isAction2}
            addEndListener={() => { }}
            classNames="alert"
            style={{
              width: '48px', height: '48px', cursor: 'pointer',
              display: isShowNextBtn ? "" : "none"
            }}
          >
            <img alt="ico-next" src={IcoNext} />
          </CSSTransition>
        </button>}
        {pictureUrlList.length > 1 &&
          <Box sx={{ position: 'absolute', zIndex: 1, bottom: '10px', display: "flex", justifyContent: "center", width: '100%' }}>
            <Pagination dots={pictureUrlList.length || 0} selectedIndex={index} onChangeIndex={handleChangeSwipeIndex} />
          </Box>
        }
      </Box>
      <Box sx={{
        position: "absolute",
        left: "46%",
        top: "46%",
        display: isShowLoading ? "" : "none",
        zIndex: "999"
      }}>
        <CircularProgress />
      </Box>


    </Box>
  )
}
export default PhotoSlider;