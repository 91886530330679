export const isWeixinBrowser = /MicroMessenger/i.test(navigator.userAgent);

export const INPUT_COMMENT_MAX_LENGTH = 1000;

export const WEBSITE_TEST = "www-test.dev.mukbee.com";
export const WEBSITE_STAGGING = "www-test.mukbee.com";
export const WEBSITE_PRODUCTION_COM = "www.mukbee.com";
export const WEBSITE_PRODUCTION_CN = "www.mukbee.cn";

export const shouldShowStaticRootPage = (): boolean => {
  const hostName = window.location.host;
  console.log("1 Mukbee website hostName: " + hostName);
  return (
    // hostName.includes('localhost') ||
    hostName === WEBSITE_PRODUCTION_COM || hostName === WEBSITE_PRODUCTION_CN
  );
};
