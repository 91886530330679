import React from 'react'
import { Button } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
import { SvgSprite } from './components/svgSprite';
import { SvgSource } from './theme';
import { LoadingBar } from './components/loading';
import { Routers, Routes } from './layout/route';

interface AppProps {
  language?: string
  routes: Routes
}
const App: React.FC<AppProps> = ({ routes }) => {
  const notistackRef = React.createRef<SnackbarProvider>()

  return (
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        ref={notistackRef}
        action={key => (
          <Button onClick={() => notistackRef?.current?.closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </Button>
        )}
        >
          <Routers routes={routes} fallback={<LoadingBar/>}/>
        <SvgSprite symbols={SvgSource} />
      </SnackbarProvider>
  )
}

export default App
