import { FC } from "react"
import { Suspense } from "react";
import './style.css'
import logo1 from './images/logo.png'
import mukbee1 from "./images/mukbee.svg"
import product1 from './images/product.png'
import slogan from './images/slogan.svg'
import ios1 from './images/ios.png'
import ios2 from './images/ios.svg'
import android1 from './images/android.png'
import android2 from './images/android.svg'
import mukbee2 from "./images/mukbee2.svg";
import slogan2 from "./images/slogan2.svg";
import start1 from './images/start.png'
import home1 from "./images/home.png";
import profile1 from './images/profile.png'
import collection1 from './images/collection.png'
import share1 from './images/share.png'

const RootHome: FC = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //Android
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    const scrollToAnchor = (anchorName: string, smooth: boolean) => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({ block: 'start', behavior: smooth ? 'smooth' : 'auto' });
            }
        }
    }

    return (
        <Suspense fallback="loading">
            <div className="rootHomePage">
                <div className="top" id="page-top">
                    <div className="container">
                        <div className="top-logo">
                            <div className="logo">
                                <img src={logo1} alt="Mukbee" />
                            </div>
                            <div className="name">
                                <img src={mukbee1} width="64" alt="Mukbee" />
                            </div>
                        </div>
                        <div className="banner">
                            <div className="banner-left">
                                <img src={product1} alt="product" />
                            </div>
                            <div className="banner-right">
                                {/* <div className="icon">
                                    <img src={logo1} alt="Mukbee" />
                                </div> */}
                                <div className="slogan">
                                    <img src={slogan} alt="Find the best dishes around the world" />
                                </div>

                                {!isAndroid && !isiOS &&
                                    <div id="web" className="download">
                                        <div className="download-left">
                                            <img src={ios1} width="130" alt="ios product" />
                                            <p>
                                                <a href="https://apps.apple.com/us/app/mukbee/id1614780623"><img src={ios2} alt="iOS product" /></a>
                                            </p>
                                        </div>
                                        <div className="download-right">
                                            <img src={android1} width="130" alt="android product" />
                                            <p>
                                                <a href="https://play.google.com/store/apps/details?id=com.dw.android.mukbee"><img src={android2} alt="Android" /></a>
                                            </p>
                                        </div>
                                    </div>
                                }

                                {isiOS &&
                                    <div id="ios" className="download">
                                        <div className="download-phone">
                                            <img src={ios1} width="130" alt="iOS" />
                                            <p>
                                                <a href="https://apps.apple.com/us/app/mukbee/id1614780623"><img src={ios2} alt="iOS" /></a>
                                            </p>
                                        </div>
                                    </div>
                                }

                                {isAndroid &&
                                    <div id="android" className="download">
                                        <div className="download-phone">
                                            <img src={android1} width="130" alt="Android" />
                                            <p>
                                                <a href="https://play.google.com/store/apps/details?id=com.dw.android.mukbee"><img src={android2} alt="Android" /></a>
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div className="title">
                                    Scan QR code or click button to download
                                </div>
                                <div className="mail hidden-xs">
                                    Need help? please contact us:<a href="mailto:support@mukbee.com">support@mukbee.com</a>
                                </div>
                                <div className="mail visible-xs">
                                    Need help? please contact us:<br />
                                    <a href="mailto:support@mukbee.com">support@mukbee.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="start">
                        <div className="start-left">
                            <h2>Welcome to <img src={mukbee2} alt="mukbee" /> </h2>
                            <div className="slogan">
                                <img src={slogan2} alt="Find the best dishes around the world" />
                            </div>
                        </div>
                        <div className="start-right">
                            <img src={start1} alt="start" />
                        </div>
                    </div>
                </div>

                <div className="hidden-xs">
                    <div className="main home">
                        <div className="main-pic">
                            <img src={home1} alt="home" />
                        </div>
                        <div className="main-text">
                            <h2>Find the best dishes near you and <br />around the world!</h2>
                            <p>Comment, like and share your favorite dishes for Bee points</p>
                        </div>
                    </div>
                </div>

                <div className="visible-xs">
                    <div className="main home">
                        <div className="main-text">
                            <h2>Find the best dishes near you and around the world!</h2>
                            <p>Comment, like and share your favorite dishes for Bee points</p>
                        </div>
                        <div className="main-pic">
                            <img src={home1} alt="home" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="main profile">
                        <div className="main-text profile-text">
                            <h2>Connect with food lovers from <br /> around the world</h2>
                            <p>Chat share and. Connect with fellow foodies</p>
                        </div>
                        <div className="main-pic">
                            <img src={profile1} alt="profile" />
                        </div>
                    </div>
                </div>

                <div className="hidden-xs">
                    <div className="main details">
                        <div className="main-pic">
                            <img src={collection1} alt="collection" />
                        </div>
                        <div className="main-text">
                            <h2>Create a food bucket list</h2>
                            <p>Save dishes and places that you want to try to a worldwide list</p>
                        </div>
                    </div>
                </div>

                <div className="visible-xs">
                    <div className="main details">
                        <div className="main-text">
                            <h2>Create a food bucket list</h2>
                            <p>Save dishes and places that you want to try to a worldwide list</p>
                        </div>
                        <div className="main-pic">
                            <img src={collection1} alt="collection" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="main profile share">
                        <div className="main-text profile-text">
                            <h2>Share your finds with the world</h2>
                            <p>Show other foodies your top finds</p>
                        </div>
                        <div className="main-pic">
                            <img src={share1} alt="share" />
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container">
                        <div>
                            <div className="bottom-logo">
                                <img src={mukbee1} alt="Mukbee" />
                            </div>
                            <div className="contact">
                                <span className="ico-download"><a href="download/Mukbee.apk">Android apk package</a></span>
                                <span className="ico-email"><a href="mailto:support@mukbee.com">support@mukbee.com</a></span>
                            </div>
                        </div>
                        <div className="line">
                            <div className="copyright">© 2023 Mukbee, Inc.</div>
                            <div className="link">
                                <a href="https://www.mukbee.com/legal/privacy.pdf" target="_bank">Privacy</a>
                                <a href="https://www.mukbee.com/legal/terms.pdf" target="_bank">Terms</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="backtop" onClick={() => { scrollToAnchor("page-top", true) }}>
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13473" width="48" height="48"><path d="M752.736 431.063C757.159 140.575 520.41 8.97 504.518 0.41V0l-0.45 0.205-0.41-0.205v0.41c-15.934 8.56-252.723 140.165-248.259 430.653-48.21 31.457-98.713 87.368-90.685 184.074 8.028 96.666 101.007 160.768 136.601 157.287 35.595-3.482 25.232-30.31 25.232-30.31l12.206-50.095s52.47 80.569 69.304 80.528c15.114-1.23 87-0.123 95.6 0h0.82c8.602-0.123 80.486-1.23 95.6 0 16.794 0 69.305-80.528 69.305-80.528l12.165 50.094s-10.322 26.83 25.272 30.31c35.595 3.482 128.574-60.62 136.602-157.286 8.028-96.665-42.475-152.617-90.685-184.074z m-248.669-4.26c-6.758-0.123-94.781-3.359-102.891-107.192 2.95-98.714 95.97-107.438 102.891-107.93 6.964 0.492 99.943 9.216 102.892 107.93-8.11 103.833-96.174 107.07-102.892 107.192z m-52.019 500.531c0 11.838-9.42 21.382-21.012 21.382a21.217 21.217 0 0 1-21.054-21.34V821.74c0-11.797 9.421-21.382 21.054-21.382 11.591 0 21.012 9.585 21.012 21.382v105.635z m77.333 57.222a21.504 21.504 0 0 1-21.34 21.626 21.504 21.504 0 0 1-21.34-21.626V827.474c0-11.96 9.543-21.668 21.299-21.668 11.796 0 21.38 9.708 21.38 21.668v157.082z m71.147-82.043c0 11.796-9.42 21.34-21.053 21.34a21.217 21.217 0 0 1-21.013-21.34v-75.367c0-11.755 9.421-21.299 21.013-21.299 11.632 0 21.053 9.544 21.053 21.3v75.366z" fill="#f4ea2a" p-id="13474"></path></svg>
                </div>
            </div>
        </Suspense>
    )
}

export default RootHome;
