export interface Locale {
  lang: string
  label?: string
  country: string
  title?: string
  icon?: string

}

export const defaultLanguage = 'en'

export const defaultLanguages: Locale[] = [
  {
    lang: 'en',
    label: 'English',
    country: 'US',
    title: 'Language',
    icon: '🇺🇸',
  },
  {
    lang: 'zh-CN',
    label: '中文简体',
    country: 'CN',
    title: '语言',
    icon: '🇨🇳',
  },
  {
    lang: 'zh-TW',
    label: '中文繁體',
    country: 'CN',
    title: '語言',
    icon: '🇭🇰',
  },
  {
    lang: 'fr',
    label: 'Français',
    country: 'FR',
    title: 'Langues',
    icon: '',
  },
]
