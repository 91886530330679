import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { MessageTypeEnum } from "../../models/post";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const homeApi = createApi({
  reducerPath: "home",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getMessageDetail: builder.mutation<Result<any>, { messageId: string }>({
        query: ({ messageId }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            is_post: true,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getMessageCommentList: builder.mutation<
        Result<any>,
        {
          messageId: string;
          forwardOrder?: boolean;
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ forwardOrder, messageId, pageSize, startKey }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            messageid: messageId,
            pagesize: pageSize ?? 20,
            forwardorder: forwardOrder || false,
            startkey: startKey || "",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const { useGetMessageDetailMutation, useGetMessageCommentListMutation } =
  homeApi;
