import { Box, Button } from "@mui/material";
import { FC, useEffect } from "react";
import WechatOpenAppBtn from "./WechatOpenAppBtn";
import { configWx, openApp } from "./openApp";
import { isWeixinBrowser } from "../../../tools/utils/appUtil";
import { useSnackbar } from "notistack";
import Axios from 'axios';
import Logo from '../../../images/logo/logo.png'
import { SvgComponent } from "src/components/svgComponent";
// import { SignatureParams, generateSignature, getJsApiTicket } from "./wxSignature";

// 微信公众平台的 app id 和 app secret
export const WX_PUBLIC_APP_ID = "";
export const WX_PUBLIC_APP_SECRET = "";

interface Props {
  onClickClose: () => void;
}
const OpenAppBox: FC<Props> = ({ onClickClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isWeixinBrowser) {
      get_signature();
    }
  }, [isWeixinBrowser])

  const get_signature = async () => {
    if (process.env.REACT_APP_ENV === 'dev') {
      enqueueSnackbar('start to request /wechat-signature', { variant: 'info' })
    }
    const configUrl = window.location.href.split('#')[0];
    Axios.create({
      baseURL: process.env.REACT_APP_GLOBAL_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    }).post<any>('/wechat-signature', {
      url: configUrl
    })
      .then(resp => {
        if (resp && resp.data && resp.status === 200) {
          const res = resp.data.data;
          /** 返回res示例:
            appid: "wxe872a1db0f540ca4"
            nonceStr: "kvi7ng"
            signature: "f258c91db91854fb4c6d6fa57b83d1de942f4e3d"
            timestamp: 1681454905
            jsApiTicket: "LIKLckvwlJT9cWIhEQTwfNO6JRD5-7zyQOfNh9PRM8LGpv5HoUesfLg1Lomd54YyPbf5msNn-FmYL9bsbXLEmA"
          */
          if (res) {
            configWx(WX_PUBLIC_APP_ID, res.timestamp, res.nonceStr, res.signature)


            /* for test
            const signature_param: SignatureParams = {
              appId: WX_PUBLIC_APP_ID,
              timestamp: res.timestamp,
              nonceStr: res.nonceStr,
              url: configUrl,
              jsApiTicket: res.jsApiTicket,
              secret: WX_PUBLIC_APP_SECRET,
            };
            console.log("signature_param->", signature_param);
            generateSignature(signature_param).then(signature => {
  
              console.log("signature->", signature);
              console.log("local signature === api signature? ", signature === res.signature );
              alert("signature 比较结果："+ (signature === res.signature));
            });
            */
          } else {
            enqueueSnackbar('Get empty Wechat signature', { variant: 'error' })
          }
        } else {
          enqueueSnackbar('Get Wechat signature failed:' + resp.status, { variant: 'error' })
        }
      }).catch(error => {
        enqueueSnackbar('Get Wechat signature error', { variant: 'error' })
      })
  }

  /*
  const configLocalSignature = async () => {
    const jsApiTicket = await getJsApiTicket(WX_PUBLIC_APP_ID, WX_PUBLIC_APP_SECRET);
    // const jsApiTicket = 'LIKLckvwlJT9cWIhEQTwfNO6JRD5-7zyQOfNh9PRM8LGpv5HoUesfLg1Lomd54YyPbf5msNn-FmYL9bsbXLEmA';

    const configUrl =  window.location.href.split('#')[0];
    // const paramUrl = encodeURIComponent(configUrl);
    const paramUrl = configUrl;
    const timeStamp = Math.floor(Date.now() / 1000);
    const noStr = Math.random().toString(36).slice(-6);
    const signature_param: SignatureParams = {
      appId: WX_PUBLIC_APP_ID,
      timestamp: timeStamp,
      nonceStr: noStr,
      url: paramUrl,
      jsApiTicket: jsApiTicket,
      secret: WX_PUBLIC_APP_SECRET,
    };
    console.log("signature_param->", signature_param);
    const signature = await generateSignature(signature_param);
    configWx(WX_PUBLIC_APP_ID, timeStamp, noStr, signature)
  }
  */

  /*
  useEffect(() => {
    configLocalSignature();
  }, [])
  */

  return (
    <Box sx={{
      width: "100%",
      height: "50px",
      background: "#FDFAED",
      display: "flex",
      alignItems: "center",
      padding: "9px 12px",
      position: 'relative',
      boxSizing: "border-box"
    }}>
      <SvgComponent iconName="ico-close-small" style={{ width: '12px', height: '12px', }} onClick={onClickClose} />
      <Box component={'img'} src={Logo}
        sx={{
          width: "32px",
          height: "32px",
          margin: "0 10px"
        }}>
      </Box>
      <Box
        style={{
          flexGrow: 1,
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
        }}
      >Open the app to find best dishes</Box>
      {isWeixinBrowser ?
        <WechatOpenAppBtn />
        :
        <Button sx={{
          width: "64px",
          height: "32px",
          marginLeft: "10px",
          position: "absolute",
          right: "12px",
          background: "#FFCE00",
          borderRadius: "16px",
          fontFamily: 'SF Pro Text',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          textAlign: "center",
          color: "#FFFFFF",
          cursor: "pointer",
          textTransform: "none",
          ':hover': {
            backgroundColor: "#FFCE00",
          }
        }} onClick={openApp}
        >Open</Button>
      }
    </Box>
  )
}

export default OpenAppBox;