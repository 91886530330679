import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { SendCommentEntity } from "../../models/comment";
import { MessageTypeEnum, ThumbType } from "../../models/post";

export const postApi = createApi({
  reducerPath: "post",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      sendComment: builder.mutation<Result<any>, { entity: SendCommentEntity }>(
        {
          query: ({ entity }) => ({
            url: `sendmessage`,
            method: "POST",
            body: entity,
          }),
          transformResponse: (res: Response<any>) =>
            new Value(res, "send_message"),
        }
      ),

      updateThumb: builder.mutation<
        Result<any>,
        {
          add: boolean;
          message_id: string;
          message_type: MessageTypeEnum;
          thumbinfo: boolean;
          type: ThumbType;
          url_type: number;
          message_url?: string;
        }
      >({
        query: ({
          add,
          message_id,
          message_type,
          thumbinfo,
          type,
          message_url,
          url_type,
        }) => ({
          url: `updatethumbinfo`,
          method: "POST",
          body: {
            add,
            message_id,
            message_type,
            thumbinfo,
            type,
            message_url,
            url_type,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const { useSendCommentMutation, useUpdateThumbMutation } = postApi;
