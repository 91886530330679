import { enqueueSnackbar } from "notistack";
import wx from "weixin-js-sdk";
const appUrlScheme = "mukbee://";

export function configWx(appId, timestamp, nonceStr, signature) {
  wx.config({
    debug: false,
    appId,
    timestamp,
    nonceStr,
    signature,
    jsApiList: ["checkJsApi"],
    openTagList: ["wx-open-launch-app"],
  });
  wx.ready(function () {
    console.log("wechat readied");
  });
  wx.error(function (res) {
    console.log("wechat error:", res);
    // alert('wechat config error: ' + JSON.stringify(res))
  });
}

// 非微信浏览器里点击打开app
export function openApp() {
  // iOS 平台
  if (
    navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("ipad") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("ipod") > -1
  ) {
    window.location.href = appUrlScheme;
    // setTimeout(() => {
    //     window.location.href = 'https://itunes.apple.com/app/myapp/id1234567890';
    // }, 2000);
  }
  // Android 平台
  else if (navigator.userAgent.indexOf("Android") > -1) {
    // const iframe = document.createElement('iframe');
    // iframe.src = "mukbee://mukbee.com";
    // iframe.style.display = 'none';
    // document.body.appendChild(iframe);
    // setTimeout(() => {
    //     document.body.removeChild(iframe);
    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.sktlab.android.mukbee';
    // }, 2000);

    openAndroidApp();
  } else {
    enqueueSnackbar("Can not open app");
  }
}

export const openAndroidApp = () => {
  window.location.href =
    "intent://#Intent;scheme=mukbee;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;end";
};
