export const AVATAR_DEFAULT =
  "https://portal.dev.veeko.com/mobile/DefaultAvatar/ic_unspecified1.png";

export const isMobileDevice = () => {
  return isAndroidMobileDevice() || isAppleMobileDevice();
};

export const isAndroidMobileDevice = () => {
  return navigator.userAgent.match(/iPhone/i);
};

export const isAppleMobileDevice = () => {
  return (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i)
  );
};

export const isProductionEnv = () => {
  return process.env.REACT_APP_ENV === "prod";
};

export const getExploreName = () => {
  var userAgent = navigator.userAgent;
  if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return "Opera";
  } else if (
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1
  ) {
    return "IE";
  } else if (userAgent.indexOf("Edge") > -1) {
    return "Edge";
  } else if (userAgent.indexOf("Firefox") > -1) {
    return "Firefox";
  } else if (
    userAgent.indexOf("Safari") > -1 &&
    userAgent.indexOf("Chrome") === -1
  ) {
    return "Safari";
  } else if (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Safari") > -1
  ) {
    return "Chrome";
  } else if ("ActiveXObject" in window) {
    return "IE>=11";
  } else {
    return "Unkonwn";
  }
};

export const isSafari = (): boolean => {
  return getExploreName() === "Safari";
};
