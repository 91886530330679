import { lazy } from 'react';
import { Routes } from './layout/route';
import RootHomePage from './pages/root/RootHome';
import MessageDetails from './pages/details/index';
import DefaultLayout from './layout/DefaultLayout';
import { shouldShowStaticRootPage } from './tools/utils/appUtil';


// define all routing in project
const paths = {
  root: '/',
  home: '/home',
  details: '/details',
}

const routes: Routes = {
  [paths.root]: { component: RootHomePage },
  [paths.details]: { component: MessageDetails },
}


export class appConfig {
  // all paths
  static get paths() {
    return paths
  }
  // resitered components
  static get routes() {
    return routes
  }
}
