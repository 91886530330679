import { Avatar, Box, Divider, Grid, Skeleton, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useGetMessageCommentListMutation, useGetMessageDetailMutation } from "../../../service/api/home-api";
import { getCoverImage, getPostLocationName, getPostLocationSubName, getPostMediaType, getVideoCoverImageUrl, getVideoUrl, MessageTypeEnum, PostEntity, PostMediaType } from "../../../models/post";
import { useSnackbar } from 'notistack';
import { howLongAgo } from "../../../tools/utils/stringUtil";
import ReactPlayer from 'react-player';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SvgComponent } from "../../../components/svgComponent";
import { Loader } from "../../../components/loader";
import FullscreenDialogPictureSlider from "../../../components/slider/fullscreen/FullscreenDialogPictureSlider";
import CommentList from "../../../components/comment/CommentList";
// import { CoverPlayer } from "../../../components/coverPlayer";
import { useNavigate } from "react-router-dom";
import { appConfig } from "../../../app-config";
import DefaultAvatar from '../../../images/defaultIcons/default-avatar.png'
import { MessageCommentEntity } from "../../../models/comment";
import { CoverPlayer } from "src/components/coverPlayer";
import PhotoSlider from "src/components/slider/photos/PhotoSlider";
import { getDistanceBetweenTwoGps, getDistanceDesc } from "src/tools/utils/locationUtil";
import ChooseMapDialog from "src/components/dialog/ChooseMapDialog";
import copy from "copy-to-clipboard";

interface Props {
  messageId: string;
  loadMoreCount: number;
}

const PostDetail: FC<Props> = ({ messageId, loadMoreCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const COMMENT_PAGE_SIZE = 20;
  const [postInfo, setPostInfo] = useState<PostEntity>();
  const { enqueueSnackbar } = useSnackbar();
  const [startKey, setStartKey] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [comments, setComments] = useState<MessageCommentEntity[]>([]);
  // const [latitude, setLatitude] = useState(0);
  // const [longitude, setLongitude] = useState(0);
  // const [distance, setDistance] = useState('');
  // const [commentCount, setCommentCount] = useState(0);
  // const [openMapDialog, setOpenMapDialog] = useState(false);

  const [pictureUrlList, setPictureUrlList] = useState<string[]>([])
  const [previewPictureConfig, setPreViewPictureConfig] = useState({
    open: false,
    photoIndex: 0
  });
  const mediaType = getPostMediaType(postInfo);
  const mediaCount = postInfo?.message_content?.uri?.length || 0;

  const [getDetail, { isLoading }] = useGetMessageDetailMutation();
  const [getComments, { isLoading: loadingList }] = useGetMessageCommentListMutation();

  const [isGoogleResponsed, setIsGoogleResponsed] = useState(true);
  const [connectEnd, setConnectEnd] = useState(false);

  useEffect(() => {
    tryToConnectGoogle()
  }, [])

  const fetchWithTimeout = async (resource, options: any) => {
    const { timeout = 5000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => {
      controller.abort();
    }, timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
      mode: 'no-cors',
      cache: 'no-cache',
    });
    clearTimeout(id);
    return response;
  }

  const tryToConnectGoogle = async () => {
    try {
      const response = await fetchWithTimeout("https://www.google.com", {
        timeout: 3000
      });
      console.log('Google responsed: ', !!response);
      setIsGoogleResponsed(!!response);
      setConnectEnd(true);
    } catch (error: any) {
      setConnectEnd(true);
      if (error && error.name === 'AbortError') {
        console.error('fetch google api timeout, set Google response false');
        setIsGoogleResponsed(false);
      } else {
        console.error('fetch google api error');
      }
    }
  }

  // useEffect(() => {
  //   if (latitude && longitude && postInfo) {
  //     const postGPS = postInfo.gps;
  //     if (postGPS) {
  //       const array = postGPS.split(',');
  //       const lat1 = parseFloat(array[0]);
  //       const lon1 = parseFloat(array[1]);
  //       const dist = getDistanceBetweenTwoGps(latitude, longitude, lat1, lon1);
  //       const desc = getDistanceDesc(dist);
  //       setDistance(desc);
  //     }
  //   }
  // }, [latitude, longitude, postInfo])


  useEffect(() => {
    // getLocationByBrowserNavigator();
    getMessageData();
    getListData('');
  }, [messageId])


  useEffect(() => {
    getListData(startKey);
  }, [loadMoreCount])


  const getMessageData = () => {
    if (!messageId) {
      return;
    }
    getDetail({ messageId: messageId }).unwrap()
      .then(res => {
        if (res && !res.isFailed && res.result) {
          const posts: PostEntity[] = res.result.messages;
          if (posts?.length > 0) {
            const post = posts[0];
            setPostInfo(post);

            // get picture url list
            const urlObjs = post.message_content.uri;
            if (urlObjs?.length > 0) {
              const picUrls: string[] = [];
              urlObjs.forEach(item => {
                if (item.type === PostMediaType.Photo) {
                  picUrls.push(item.url || item.compressed_url);
                }
              })
              setPictureUrlList(picUrls);
            }
          }
        } else {
          enqueueSnackbar(res.message || "Can not get post data")
        }
      })
  }

  const getListData = (startKey: string) => {
    if (loadingList || !hasMore) {
      // console.log('list is loading more, waiting..................................')
      return;
    }
    getComments({
      messageId: messageId,
      forwardOrder: false,
      pageSize: COMMENT_PAGE_SIZE,
      startKey: startKey
    }).unwrap()
      .then(res => {
        if (res && !res.isFailed && res.result) {
          const newList: MessageCommentEntity[] = res.result.messages;
          const addedList: MessageCommentEntity[] = [];
          if (newList?.length > 0) {
            newList.forEach(item => {
              if (!comments.find(c => c.messageid === item.messageid) && !addedList.find(c => c.messageid === item.messageid)) {
                addedList.push(item);
              }
            })
            setComments([...comments, ...addedList]);
          }
          setStartKey(res.result.startkey);
          setHasMore(!!res.result.startkey);
        } else {
          console.log("no comments")
          setHasMore(false);
        }
      })
  }

  const handleClick = (mediaIndex: number) => {
    setPreViewPictureConfig({
      open: true,
      photoIndex: mediaIndex,
    })
  }

  // const getLocationByBrowserNavigator = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
  //       // setLocating(false);
  //       console.log("current navigator location: " + position.coords.latitude + "," + position.coords.longitude);
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //     }, (error: GeolocationPositionError) => {
  //       getPositionError(error);
  //       // setLocating(false);
  //       console.log("Can not get geo location by navigator ")
  //       // getLocationByAMap();
  //     }, { enableHighAccuracy: true, timeout: 10 * 1000, maximumAge: 60 * 1000 })
  //   } else {
  //     // setLocating(false);
  //     console.log("Geo location is not supported")
  //     // getLocationByAMap();
  //   }
  // }

  // const getPositionError = (error: GeolocationPositionError) => {
  //   switch (error.code) {
  //     case error.TIMEOUT:
  //       console.log("Get location time out", { variant: 'warning' });
  //       break;
  //     case error.PERMISSION_DENIED:
  //       console.log("Please allow the location permission in system preferences or browser settings", { variant: 'warning' });
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       console.log("Location sharing service is unavailable, please check the location sevice settings", { variant: 'warning' });
  //       break;
  //     default:
  //       console.log("Can not get current location", { variant: 'warning' })
  //   }
  // }

  const openMapApp = () => {
    if (!connectEnd) {
      enqueueSnackbar("Map is initing, please try again after 3 seconds", { variant: 'info' });
      return;
    }
    const postGPS = postInfo?.gps;
    if (postGPS) {
      const array = postGPS.split(',');
      const lat1 = parseFloat(array[0]);
      const lon1 = parseFloat(array[1]);
      if (isGoogleResponsed) {
        // window.location.href = 'https://uri.amap.com/marker?position=' + 108.88 + ',' + 34.19 + '&name=' + "Xi'an" + '&callnative=1';
        window.location.href = `https://www.google.com/maps/search/?api=1&query=${lat1}%2C${lon1}`;
      } else {
        const url = 'https://uri.amap.com/marker?position=' + lon1 + ',' + lat1 + '&name=' + getPostLocationName(postInfo?.address?.name) + '&callnative=1';
        window.location.href = url;
      }
    } else {
      enqueueSnackbar('No position information', { variant: 'info' })
    }

  }

  const copyAddress = () => {
    const location = getPostLocationName(postInfo?.address?.formatted_address);
    copy(location);
    enqueueSnackbar('Address is copied', { variant: 'success' })
  }

  return (
    <Box sx={{ paddingBottom: '30px' }}>
      {/* 顶部用户信息 */}

      <Box sx={isMobile ? { display: 'flex', alignItems: 'center', padding: '15px' } : { display: 'flex', alignItems: 'center', padding: '25px' }}>
        {isLoading ? <Skeleton variant="circular" width={isMobile ? 37 : 48} height={isMobile ? 37 : 48} /> : <Avatar sx={isMobile ? { width: '37px', height: '37px' } : { width: '48px', height: '48px' }} src={postInfo?.user?.user_avatar || DefaultAvatar} />}
        {isLoading ? <Skeleton variant="text" sx={{ fontSize: '18px', margin: '0 10px' }} width={200} /> :
          <Box sx={{ textAlign: 'left', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 700, fontSize: '18px', color: '#303030', margin: '0 10px' }}
          >{postInfo?.user?.user_name || postInfo?.userid}
          </Box>
        }
      </Box>

      <Box sx={isMobile ? {} : { display: 'flex', padding: '0 25px' }}>
        {/* 桌面布局左边 */}
        <Box sx={{ flexGrow: 1, position: 'relative', textAlign: 'center' }}>
          {isLoading ?
            <Skeleton variant="rectangular" sx={isMobile ? { width: '100%', height: '300px' } : { width: '700px', height: '500px' }} />
            :
            <Box sx={isMobile ? { width: '100%', height: 'auto' } : { width: '700px', height: 'auto' }}>
              {/* 视频 */}
              {mediaType === PostMediaType.Video &&
                <Box sx={isMobile ? { height: 'auto' } : { height: '600px' }}>
                  <CoverPlayer videoUrl={getVideoUrl(postInfo)} imageUrl={getVideoCoverImageUrl(postInfo)} playerType="video" />
                </Box>
              }
              {/* 单张照片 */}
              {mediaType === PostMediaType.Photo && mediaCount === 1 &&
                <img alt="post_image"
                  onClick={() => handleClick(0)}
                  src={getCoverImage(postInfo)}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    objectPosition: 'left',
                    [theme.breakpoints.down('sm')]: {
                      objectPosition: 'center',
                    }
                  }} />
              }
              {/* 九宫格照片  */}
              {mediaType === PostMediaType.Photo && mediaCount > 1 &&
                <PhotoSlider pictureUrlList={pictureUrlList} photoIndex={0} />
              }

            </Box>
          }

          {/* Post文字内容 */}
          <Box sx={isMobile ? { padding: '0 15px' } : {}}>
            <Box sx={{ paddingTop: '15px', textAlign: 'left', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 700, fontSize: '20px', color: 'black', display: 'flex', alignItems: 'center' }}>
              <SvgComponent iconName="ico-mukbee" style={{ width: '20px', height: '15px', marginRight: '5px' }} />
              {isLoading ? <Skeleton variant="text" sx={{ fontSize: '20px', marginRight: '5px' }} width={200} /> : postInfo?.food_name}
            </Box>

            {isLoading ? <Skeleton variant="text" sx={{ margin: '15px 0', fontSize: '15px', marginRight: '5px' }} width={300} /> :
              <Box sx={{ padding: '15px 0', textAlign: 'left', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '15px', color: '#303030', }}>
                {postInfo?.message_content?.description}
              </Box>
            }

            {isLoading ? <Skeleton variant="text" sx={{ fontSize: '12px', marginRight: '5px' }} width={100} /> :
              <Box sx={{ textAlign: 'left', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '12px', color: '#555555' }}>{postInfo ? howLongAgo(postInfo.timestamp) : ''}</Box>
            }

            <Divider sx={{ marginTop: '15px' }} />

            {/* 位置信息 */}
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '9px 0' }} >
              <SvgComponent iconName="ico-location-grey" style={{ width: '11px', height: '14px', marginLeft: '2px', marginRight: '8px' }} />
              {isLoading ? <Skeleton variant="text" sx={{ fontSize: '12px', }} width={100} /> :
                <Box sx={{
                  fontFamily: 'Helvetica Neue',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#303030',
                  lineHeight: '14px',
                  textAlign: 'left',
                  flexGrow: 1,
                }}
                >{getPostLocationName(postInfo?.address?.name)}
                </Box>
              }
            </Box>
            {/* 评分 */}
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '9px 0' }} >
              <SvgComponent iconName="ico-star" style={{ width: '15px', height: '15px', marginRight: '7px' }} />
              {isLoading ? <Skeleton variant="text" sx={{ fontSize: '12px', }} width={100} /> :
                <Box sx={{
                  fontFamily: 'Helvetica Neue',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#707070',
                  lineHeight: '14px',
                  textAlign: 'left',
                }}
                >{postInfo?.rating ?? '4.5'}
                </Box>
              }
              {/* {distance &&
                <Box sx={{
                  flexGrow: 1,
                  fontFamily: 'Helvetica Neue',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#707070',
                  lineHeight: '14px',
                  textAlign: 'left',
                }}
                >&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{distance}
                </Box>
              } */}
            </Box>
            {/* 详细位置 */}
            {isLoading ? <Skeleton variant="text" sx={{ fontSize: '12px', margin: '9px 0' }} width={300} /> :
              <Box sx={{ display: 'flex', alignItems: 'start', margin: '9px 0' }}>
                <SvgComponent iconName="ico-map-small" style={{ width: '9px', height: '10px', marginTop: '3px', marginLeft: '3px', marginRight: '10px' }} />
                <Box sx={{
                  fontFamily: 'Helvetica Neue',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#707070',
                  lineHeight: '14px',
                  textAlign: 'left',
                  maxWidth: 'calc(100% - 150px)'
                }}
                >{getPostLocationName(postInfo?.address?.formatted_address)}
                </Box>


                <Box sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => openMapApp()}>
                  <SvgComponent iconName="ico-go" style={{ width: '16px', height: '16px', margin: '0 5px' }} />
                  <Box sx={{
                    fontFamily: 'Helvetica Neue',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#0075FF',
                    lineHeight: '14px',
                    textAlign: 'left',
                  }}
                  >Go
                  </Box>
                </Box>


                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
                  onClick={copyAddress}>
                  <SvgComponent iconName="ico-copy" style={{ width: '16px', height: '16px', margin: '0 5px' }} />
                  <Box sx={{
                    fontFamily: 'Helvetica Neue',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#0075FF',
                    lineHeight: '14px',
                    textAlign: 'left',
                  }}
                  >Copy
                  </Box>
                </Box>

              </Box>
            }
            {isMobile && <Divider />}
          </Box>
        </Box>

        {/* 桌面布局右边评论部分 */}
        <Box style={isMobile ? { marginTop: '10px', padding: '0 15px' } : { width: '400px', paddingLeft: '50px' }} sx={{ background: 'white', display: 'flex', flexDirection: 'column' }}>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{
              fontFamily: 'Helvetica Neue',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '14px',
              color: '#000000',
              lineHeight: '20px',
              textAlign: 'left',
            }}>
              {postInfo && postInfo?.commentcount > 1 ? `${postInfo?.commentcount} Comments` : `${postInfo?.commentcount || 0} Comment`}
            </Box>

            <Box sx={{
              fontFamily: 'Helvetica Neue',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#000000',
              lineHeight: '20px',
              textAlign: 'right',
            }}>
              {postInfo && postInfo?.thumbup > 1 ? `${postInfo?.thumbup} Likes` : `${postInfo?.thumbup || 0} Like`}
            </Box>
          </Box>

          {loadingList ? '' :
            <CommentList viewOnly={true} comments={comments} />
          }

          {!hasMore && comments?.length > 0 && <Box sx={{ color: '#999999', fontSize: '12px', textAlign: 'center' }}>No more data.</Box>}
        </Box>

      </Box>

      <FullscreenDialogPictureSlider
        showDialog={previewPictureConfig.open}
        pictureUrlList={pictureUrlList}
        photoIndex={previewPictureConfig.photoIndex || 0}
        onClose={() => {
          setPreViewPictureConfig({ open: false, photoIndex: 0 })
        }}
      />

      {/* <ChooseMapDialog
        open={openMapDialog}
        onClose={() => setOpenMapDialog(false)}
        onSelectValue={(value) => openMapApp(value)} /> */}

    </Box>
  )
}

export default PostDetail;