import React, { ReactElement } from 'react';

interface SvgComponentProps {
  iconName: string;
  className?: string;
  [key: string]: string | number | object | undefined;
}

export default function SvgComponent({
  iconName,
  className = '',
  ...rest
}: SvgComponentProps): ReactElement {
  const classNameArr = ['icon'];
  const otherAttr = { ...rest };
  classNameArr.push(`icon_${iconName}`);
  classNameArr.push(className);
  delete otherAttr.name;
  delete otherAttr.className;

  return (
    <svg
      className={classNameArr.join(' ').trim()}
      {...otherAttr}
    >
      <use xlinkHref={`#${iconName}`} />
    </svg>
  );
}
