export interface PostEntity {
  address: AddressEntity;
  commentcount: number;
  food_name: string;
  gps: string; //"34.19450798913586, 108.87621593292855"
  // language: string;
  // loc_id: string;
  // loc_name: string;
  message_content: PostMessageContent;
  // message_hash: string;
  message_type: MessageTypeEnum.Message;
  messageid: string;
  // popular_weight: number;
  // received_timestamp: number;
  // sub_location_id: string;
  // thumb_from_you: boolean;
  // thumbdown: number;
  thumbup: number;
  timestamp: number;
  user: {
    uuid: number;
    user_avatar: string;
    user_id: string;
    user_name: string;
  };
  userid: string;
  rating: number;
  // product?: {
  //   title: string;
  //   id: string;
  // };
}

export interface AddressEntity {
  administrative_area: { name: string };
  country: { name: string; short_name: string };
  formatted_address: string;
  locality: { name: string };
  name: string;
  subadministrative_area: { name: string };
}

export interface LiveBroadcastEntity {
  broadcast: BroadcastInfo;
  commentcount: number;
  gps: string; //"34.19450798913586, 108.87621593292855"
  language: string;
  loc_id: string;
  loc_name: string;
  message_type: MessageTypeEnum.LiveBroadcast;
  messageid: string;
  popular_weight: number;
  received_timestamp: number;
  sub_location_id: string;
  thumb_from_you: boolean;
  thumbdown: number;
  thumbup: number;
  timestamp: number;
  user_privilege: number;
  useravatar: string;
  userid: string;
  username: string;
}

export interface BroadcastInfo {
  broadcast_channel: {
    authorized: boolean;
    channel_arn: string;
    channel_name: string;
    channel_region: string;
    channel_type: string;
    enable_playback: boolean;
    ingest_endpoint: string;
    latency_mode: string;
    playback_url: string;
    push_url: string;
    replay_url: string;
    streamKey_arn: string;
    streamKey_value: string;
  };
  broadcast_fees: number;
  broadcast_real_status: boolean;
  broadcast_start_time: number;
  broadcast_status: BroadcastStatusEnum; //1-end, 2-created, 3-start
  broadcast_title: string;
  broadcast_cover: string;
}

export enum BroadcastStatusEnum {
  END = 1,
  Created = 2,
  Start = 3,
}

export enum MessageTypeEnum {
  Message = 1,
  Request = 2,
  Response = 3,
  Request_Response = 4,
  LiveBroadcast = 5,
}

export enum PostMediaType {
  Unknown = -1,
  Photo = 1,
  Video = 2,
}

export enum ThumbType {
  Message = 1,
  User = 2,
}

export interface UriEntity {
  compressed_url: string;
  hash: string;
  thumb_from_you: boolean;
  thumb_up: number;
  timestamp: number;
  type: PostMediaType;
  url: string;
  height: number;
  width: number;
}

export const getPostLocationName = (locName: string | undefined) => {
  if (!locName) {
    return "";
  }
  const index = locName.indexOf(",");
  return index === -1 ? locName : locName.substring(0, index);
};

export const getPostLocationSubName = (locName: string | undefined) => {
  if (!locName) {
    return "";
  }
  const index = locName.indexOf(",");
  return index === -1
    ? locName
    : locName.length > index + 1
    ? locName.substring(index + 1)
    : locName;
};

export const getPictureUrlList = (post: PostEntity | undefined) => {
  if (!post || !post.message_content) {
    return [];
  }
  const urlObjs = post.message_content.uri;
  if (urlObjs?.length > 0) {
    const picUrls: string[] = [];
    urlObjs.forEach((item) => {
      if (item.type === PostMediaType.Photo) {
        picUrls.push(item.url || item.compressed_url);
      }
    });
    return picUrls;
  }
  return [];
};

export const getVideoUrl = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.url;
  } else {
    return "";
  }
};

export const getVideoCoverImageUrl = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.compressed_url;
  } else {
    return "";
  }
};

export const getCoverImage = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const imageItem = uris.find((item) => item.type === PostMediaType.Photo);
  if (imageItem) {
    return imageItem.url || imageItem.compressed_url;
  } else {
    return "";
  }
};

export const getPostMediaType = (
  post: PostEntity | undefined
): PostMediaType => {
  if (!post || !post.message_content) {
    return PostMediaType.Unknown;
  }

  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return PostMediaType.Unknown;
  }
  return uris[0].type;
};

export const getPlayUrl = (liveInfo: BroadcastInfo | undefined) => {
  if (!liveInfo) {
    return;
  }
  if (
    liveInfo?.broadcast_status === BroadcastStatusEnum.Start &&
    liveInfo?.broadcast_channel?.playback_url
  ) {
    return liveInfo?.broadcast_channel?.playback_url;
  }
  if (liveInfo?.broadcast_channel?.replay_url) {
    return liveInfo?.broadcast_channel?.replay_url;
  }
  return "";
};

export const isLiving = (liveInfo: BroadcastInfo | undefined): boolean => {
  if (!liveInfo) {
    return false;
  }
  return (
    liveInfo.broadcast_status === BroadcastStatusEnum.Start &&
    !!liveInfo.broadcast_channel?.playback_url
  );
};
export interface PostMessageContent {
  description: string;
  mention?: any[];
  topic?: any[];
  type: string;
  uri: UriEntity[];
  // paidInfo?: RewardPaidInfo,
  for_sell?: boolean;
  price?: number;
}
