import { Avatar, Box, Button, Dialog, DialogContent, Divider, Paper, PaperProps, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SvgComponent } from "../svgComponent";
import { useGetMessageCommentListMutation } from "../../service/api/home-api";
import { useSnackbar } from "notistack";
import { MessageTypeEnum, ThumbType } from "../../models/post";
import { MessageCommentEntity, SendCommentEntity } from "../../models/comment";
import { getCommentTimeDesc } from "../../tools/utils/timeUtil";
import { getNumberDesc } from "../../tools/utils/stringUtil";
import { useSelector } from "../../tools/toolkit/store";
import { useDispatch } from "react-redux";
import { showSignInUpDialog } from "../../service/redux/signinup-slice";
import { useSendCommentMutation, useUpdateThumbMutation } from "../../service/api/post-api";
import BGReward from '../../images/defaultIcons/bg-reward.png'
import RedPacket from '../../images/defaultIcons/ico-red-packet.png'
import { AVATAR_DEFAULT } from "src/tools/utils/deviceUtil";
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
    open: boolean;
    onClickClose: () => void;
    comment: MessageCommentEntity;
    viewOnly: boolean;
    onSendSubComment: () => void;
    onThumbUpdate: (thumbed: boolean) => void;
}

const PaperComponent = (props: PaperProps) => {
    return (
        <Paper {...props} style={{ borderRadius: "12px" }} />
    );
}

const SubCommentListDialog: FC<Props> = ({ open, onClickClose, comment, viewOnly, onSendSubComment, onThumbUpdate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const LIST_BOX_ID = 'sub-comment-list-box';
    const { enqueueSnackbar } = useSnackbar();
    const { credential } = useSelector(state => state.app);
    const dispatch = useDispatch();
    const listBox = document.getElementById(LIST_BOX_ID);
    const COMMENT_PAGE_SIZE = 10;
    const [inputValue, setInputValue] = useState('');
    const [startKey, setStartKey] = useState('');
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [comments, setComments] = useState<MessageCommentEntity[]>([]);
    const [commentCount, setCommentCount] = useState<number>(comment?.commentcount ?? 0);
    const [thumbed, setThumbed] = useState<boolean>(comment?.thumb_from_you || false);
    const [thumbCount, setThumbCount] = useState<number>(comment?.thumbup ?? 0);



    const [getComments, { isLoading: loadingList }] = useGetMessageCommentListMutation();
    const [updateThumb] = useUpdateThumbMutation();
    const [sendComment] = useSendCommentMutation();


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSend();
        }
    }

    useEffect(() => {
        if (!comment) {
            return;
        }
        setStartKey('');
        setHasMore(true);
        getListData('', true);
    }, [comment])


    const handleListScroll = () => {
        if (!listBox) {
            return;
        }
        const scrollHeight = listBox.scrollHeight;
        const scrollTop = listBox.scrollTop;
        const windowHeight = listBox.clientHeight;
        if ((scrollHeight - scrollTop - windowHeight < 50)) {
            // console.log('scroll bottom.....................')
            getListData(startKey, false);
        }
    }

    const getListData = (startKey: string, isRefresh: boolean) => {
        if (loadingList || (!isRefresh && !hasMore)) {
            return;
        }
        getComments({
            messageId: comment?.messageid || '',
            forwardOrder: false,
            pageSize: COMMENT_PAGE_SIZE,
            startKey: startKey
        }).unwrap()
            .then(res => {
                if (res && !res.isFailed && res.result) {
                    const newList: MessageCommentEntity[] = res.result.messages;
                    const addedList: MessageCommentEntity[] = [];
                    if (newList?.length > 0) {
                        newList.forEach(item => {
                            if (!comments.find(c => c.messageid === item.messageid) && !addedList.find(c => c.messageid === item.messageid)) {
                                addedList.push(item);
                            }
                        })
                        setComments([...comments, ...addedList]);
                    }
                    setStartKey(res.result.startkey);
                    setHasMore(!!res.result.startkey);
                } else {
                    console.log("no comments")
                    setHasMore(false);
                }
            })
    }


    const handleUpdateThumb = () => {
        if (!credential) {
            dispatch(showSignInUpDialog());
            return;
        }
        if (!comment) {
            return;
        }
        const newThumbed = !thumbed;
        setThumbed(newThumbed)
        if (newThumbed) {
            setThumbCount(count => count + 1);
        } else {
            setThumbCount(count => count > 0 ? count - 1 : 0);
        }

        updateThumb({ add: newThumbed, message_id: comment?.messageid, message_type: MessageTypeEnum.Message, thumbinfo: true, type: ThumbType.Message, url_type: 0 })
            .unwrap()
            .then(res => {
                if (res && !res.isFailed) {
                    onThumbUpdate && onThumbUpdate(newThumbed);
                }
            });
    }

    const handleSend = () => {
        if (!credential) {
            dispatch(showSignInUpDialog());
            return;
        }
        if (!inputValue) {
            return;
        }

        const entity: SendCommentEntity = {
            action: 'sendmessage',
            message: {
                description: inputValue,
            },
            message_type: MessageTypeEnum.Message,
            parent_message_id: comment.messageid,
        }
        sendComment({ entity: entity }).unwrap().then(res => {
            if (res && !res.isFailed) {
                setInputValue("");
                setCommentCount(count => count + 1);
                getListData('', true);
                onSendSubComment && onSendSubComment();
            } else {
                enqueueSnackbar(res?.message || 'Send comment failed', { variant: 'error' });
            }
        }).catch(e => { enqueueSnackbar(e.data?.result?.message || 'Send comment failed', { variant: 'error' }); })
    }

    return (
        <Dialog open={open} maxWidth='lg' onClose={onClickClose} PaperComponent={PaperComponent}>
            <DialogContent sx={{ maxHeight: '580px', borderRadius: '12px', display: 'flex', flexDirection: 'column' }}
                style={isMobile ? { minWidth: '70vw' } : { minWidth: '480px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ fontFamily: 'Helvetica Neue', fontSize: '18px', fontWeight: 700, color: 'black' }}>All Replies</Box>
                    <SvgComponent iconName="ico-close-small" style={{ width: '14px', height: '14px', cursor: 'pointer' }} onClick={onClickClose} />
                </Box>

                <Box sx={{ display: "flex", padding: '15px 0' }}>
                    <Avatar alt='personal information' src={comment.user?.user_avatar || AVATAR_DEFAULT} style={{ width: "32px", height: "32px" }}></Avatar>
                    <Box sx={{ width: '100%' }}>
                        <Box style={{ marginLeft: "12px" }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{
                                    textAlign: 'left',
                                    fontFamily: "SF Pro Text !important",
                                    fontStyle: "normal",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    color: "#666666",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}>{comment.user?.user_name || comment.userid}</Typography>
                                <Typography sx={{
                                    marginLeft: '12px',
                                    marginTop: '6px',
                                    fontFamily: 'SF Pro Text',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: '#999999',
                                    textAlign: 'left',
                                }}>
                                    {getCommentTimeDesc((comment.timestamp * 1000))}
                                </Typography>
                            </Box>
                            <Box sx={{
                                marginTop: '5px',
                                textAlign: 'left',
                                fontFamily: "SF Pro Text !important",
                                fontStyle: "normal",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#222222",
                            }}>{comment.message_content.description}
                            </Box>

                        </Box>

                        {!viewOnly &&
                            <Box sx={{ color: '#666666', fontSize: '14px', marginLeft: "12px", display: 'flex', alignItems: 'center', marginTop: '12px', justifyContent: 'left' }}>
                                <SvgComponent iconName="ico-comment" style={{ width: '16px', height: '16px', marginRight: '4px' }} />
                                {commentCount ? getNumberDesc(commentCount) : 0}
                                <SvgComponent iconName={thumbed ? "ico-liked" : "ico-like"} style={{ width: '16px', height: '16px', marginLeft: '15px', marginRight: '4px', cursor: 'pointer' }}
                                    onClick={handleUpdateThumb} />
                                {thumbCount ? getNumberDesc(thumbCount) : 0}
                            </Box>
                        }

                    </Box>
                </Box>

                <Box sx={{ flexGrow: 1, padding: '15px', overflowY: 'scroll', marginLeft: '15px' }}
                    onScroll={handleListScroll}
                    id={LIST_BOX_ID}>

                    {comments.map((item, index) => (
                        <Box key={index} sx={{ padding: "12px 0", }} >
                            <Box sx={{ display: "flex", }}>
                                <Avatar alt='personal information' src={item.user?.user_avatar || AVATAR_DEFAULT} style={{ width: "24px", height: "24px" }}></Avatar>
                                <Box sx={{ width: '100%' }}>
                                    <Box style={{ marginLeft: "6px" }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography sx={{
                                                textAlign: 'left',
                                                fontFamily: "SF Pro Text !important",
                                                fontStyle: "normal",
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#666666",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}>{item.user?.user_name || item.userid}
                                            </Typography>
                                            <Typography sx={{
                                                marginLeft: '12px',
                                                marginTop: '6px',
                                                fontFamily: 'SF Pro Text',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                color: '#999999',
                                                textAlign: 'left',
                                            }}>
                                                {getCommentTimeDesc((item.timestamp * 1000))}
                                            </Typography>
                                        </Box>

                                        <Box sx={{
                                            marginTop: '5px',
                                            textAlign: 'left',
                                            fontFamily: "SF Pro Text !important",
                                            fontStyle: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "#222222",
                                        }}>{item.message_content.description}
                                        </Box>

                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                    ))}

                    <Box sx={{ color: '#999999', fontSize: '12px', textAlign: 'center' }}>{loadingList ? 'Loading...' : (hasMore ? 'Scroll to view more' : '')}</Box>
                </Box>

                {!viewOnly &&
                    <Box sx={{ height: '20px', display: 'flex', alignItems: 'center', padding: '15px 0 15px 15px', marginLeft: '32px' }} >
                        <TextField
                            variant="standard"
                            placeholder={`Reply to ${comment.user?.user_name || comment.userid || 'this user'}:`}
                            sx={{ flexGrow: 1 }}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        >

                        </TextField>
                        <Button disabled={!inputValue} variant="text" sx={{ textTransform: 'none', color: '#3A92F9' }} onClick={handleSend}>Send</Button>
                    </Box>
                }

            </DialogContent>
        </Dialog>

    )
}


export default SubCommentListDialog;