import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box, IconButton, useTheme
} from '@mui/material'
import ReactPlayer from 'react-player'
import useMediaQuery from '@mui/material/useMediaQuery';
import IconPlayVideo from '../../images/post/ico-play-video.svg'
import { BroadcastInfo, MessageTypeEnum, PostEntity, } from '../../models/post';
import { getTimeInWordDescription2 } from '../../tools/utils/timeUtil';
import DefaultLiveCover from '../../images/defaultIcons/default-pic-live.png'

interface Props {
  playerType: string;// 'video'|'live'
  videoUrl?: string;
  imageUrl: string;
  liveInfo?: {
    broadcast: BroadcastInfo,
    useravatar: string,
    userid: string,
    username: string,
  },
  post?: PostEntity,
}

const CoverPlayer: React.FC<Props> = ({ playerType, videoUrl, imageUrl, liveInfo, post }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDetail, setShowDetail] = useState<boolean>(false)

  useEffect(() => {
    if (playerType === 'live' && videoUrl) {
      setPlayUrl(videoUrl);
    }
  }, [playerType, videoUrl])

  const [playUrl, setPlayUrl] = useState("");

  const handleClickPlay = () => {
    if (videoUrl) {
      setPlayUrl(videoUrl);
      setShowDetail(true);
    }
  }

  if (playUrl) {
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
        margin: "0 auto",
      }}>
        <ReactPlayer
          url={playUrl}
          playing={true}
          muted={false}
          width={"100%"}
          height={isMobile ? "auto" : "100%"}
          controls
          style={{
            border: "none",
            background: "black"
          }}
          // Disable download button
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          // Disable right click
          onContextMenu={e => e.preventDefault()}
          playsinline={true}
        />
        {/* {post&&
        <MessageFullscreenDialog
          messageType={MessageTypeEnum.Message}
          messageId={post.messageid}
          photoIndex={0}
          open={showDetail}
          onClose={() => {setShowDetail(false);setPlayUrl("");}}
          showCloseButton={true} />
        } */}
      </Box>
    )
  } else {
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
        margin: "0 auto",
      }}>
        <Box sx={{
          width: '100% !important',
          height: "inherit",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }} >
          <img alt="thumbnail" src={playerType === 'video' ? imageUrl : (imageUrl || DefaultLiveCover)}
            style={{
              width: "100%",
              height: isMobile ? "auto" : "100%",
              objectPosition: "left",
              objectFit: "cover",
              // borderRadius: '12px'
            }}
          />

          {playerType === 'video' &&
            <Box
              sx={{
                width: "100% !important",
                textAlign: "center",
                position: "absolute",
              }}>
              <IconButton onClick={handleClickPlay}>
                <img src={IconPlayVideo} alt='play_video' />
              </IconButton>

            </Box>
          }

          {playerType === 'live' &&
            <Box
              sx={{
                width: "100% !important",
                textAlign: "center",
                position: "absolute",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Avatar src={liveInfo?.useravatar} sx={isMobile ? { width: '48px', height: '48px', border: '2px solid white' } : { width: '64px', height: '64px', border: '2px solid white' }} />
              <Box style={{
                marginTop: '12px', textShadow: '1px 1px 1px black',
                fontFamily: 'Helvetica Neue', fontWeight: 400, color: 'white',
              }}
                sx={isMobile ? { fontSize: '14px' } : { fontSize: '18px' }}>{liveInfo?.username || liveInfo?.userid}
              </Box>
              <Box style={{
                marginTop: '20px', textShadow: '2px 2px 2px black',
                fontFamily: 'Helvetica Neue', fontWeight: 600, color: 'white',
              }}
                sx={isMobile ? { fontSize: '18px' } : { fontSize: '26px' }}>{liveInfo ? `Live Starting at ${getTimeInWordDescription2(liveInfo?.broadcast.broadcast_start_time * 1000)}` : ''}
              </Box>
            </Box>
          }
        </Box>
      </Box>
    )
  }
}
export default CoverPlayer;