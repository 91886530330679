import { configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import app from "../../service/redux/app-slice";
import tokenRes from "../../service/redux/access-slice";
import signinup from "../../service/redux/signinup-slice";
import { authApi } from "../../service/api/auth-api";
import { homeApi } from "../../service/api/home-api";
import { userApi } from "../../service/api/user-api";
import { postApi } from "../../service/api/post-api";
import { followApi } from "../../service/api/follow-api";

export const toolkitStore = configureStore({
  reducer: {
    app,
    tokenRes,
    signinup,
    [authApi.reducerPath]: authApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [followApi.reducerPath]: followApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      //https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: {
        ignoredActionPaths: [
          "payload",
          "meta.baseQueryMeta.request",
          "meta.baseQueryMeta.response",
        ],
        ignoredPaths: [
          "auth.mutations",
          "auth.queries",
          "home.mutations",
          "home.queries",
          "user.mutations",
          "user.queries",
          "post.mutations",
          "post.queries",
          "follow.mutations",
          "follow.queries",
        ],
      },
    })
      .concat(authApi.middleware)
      .concat(homeApi.middleware)
      .concat(userApi.middleware)
      .concat(postApi.middleware)
      .concat(followApi.middleware);
  },
});

export type AppDispatch = typeof toolkitStore.dispatch;
export type RootState = ReturnType<typeof toolkitStore.getState>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
