import { Box } from '@mui/material';
import React from 'react';

interface PaginationDotProps {
  onClick: () => void,
  active: boolean
}

const PaginationDot: React.FC<PaginationDotProps> = ({ onClick, active, ...props }) => {

  return (
    <Box style={{
      cursor: 'pointer',
      border: 0,
      background: 'none',
      padding: 0,
    }}
      onClick={onClick}
    >
      <Box style={active ? {
        backgroundColor: '#FFB500',
        height: 6,
        width: 6,
        borderRadius: 3,
        margin: 2,
      } : {
        backgroundColor: '#CCCCCC',
        height: 6,
        width: 6,
        borderRadius: 3,
        margin: 2,
      }} />
    </Box>
  )
}

export default PaginationDot;