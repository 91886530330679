import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, BaseQueryApi } from '@reduxjs/toolkit/query/react'
// import { getStoredAccessToken, storeAccessToken } from 'src/utils/localStorage'
// import { showLogoutDialog } from '../service/signinup-slice'
// import { getFormatDisplayTime } from 'src/utils/timeUtil'
// import  Axios, { AxiosError, AxiosResponse } from 'axios';
import { ThunkDispatch } from '@reduxjs/toolkit'
import { getFormatDisplayTime } from '../utils/timeUtil'
import { decodeJWT } from '../utils/auth'
import { RootState } from './store';
import { signInRefreshToken } from '../utils/refreshToken';
import { checkResponse } from '../utils/api';
import { defaultLanguage } from '../../models/locale';
import { clearRefreshToken, clearToken, setRefreshToken, setToken } from '../../service/redux/access-slice';
import { clearIdentity, setIdentity } from '../../service/redux/app-slice';
import { settings } from '../../service/settings';

const TOKEN_REFRESH_WHITE_LIST_APIS = [
  'signinpw',
  'thirdpartylogin',
  'signupcheckname',
  'signup',
  'signupverifycode',
  'getuserprofile',
  'signin',
  'forgetpassword',
];

const queryBuilder = (path?: string) => ({
  baseUrl: path ?? process.env.REACT_APP_API_URL,
  prepareHeaders(headers: any, { getState }: any) {
    const { tokenRes, app } = getState() as RootState
    if (Boolean(tokenRes.token)) {
      const localToken = localStorage.getItem(settings.keys.accessToken);
      let token = tokenRes.token;
      if (localToken && localToken !== tokenRes.token) {
        // setToken(localToken);
        token = localToken;
      }
      // headers.set('Authorization', `${tokenRes.token}`)
      headers.set('Authorization', `${token}`)
    }
    // headers.set('Authorization', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiJ3dWNoZW4iLCJ1c2VybmFtZSI6IiIsInVzZXJhdmF0YXIiOiIiLCJncHMiOiIzNC4zMjA2NTMsMTA4Ljc3ODI5MyIsImFjY3VyYWN5IjoxLCJpc19hbm9ueW1vdXMiOmZhbHNlLCJ1c2VyX3ByaXZpbGVnZSI6MiwiaWF0IjoxNjg0NDg0MTI3LCJleHAiOjE2ODQ0ODc3Mjd9.PIC7pBcSfWDMokyfWf3-TT_gNtuZSQtLN_OtzFuy4ww`)
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', app.language ?? defaultLanguage)

    return headers
  }
})

const baseQuery = fetchBaseQuery(queryBuilder());

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { tokenRes } = api.getState() as RootState
  if (!tokenRes || !tokenRes.token) {
    return queryWithReportError(args, api, extraOptions);
  }
  const tokenData = decodeJWT(tokenRes.token);
  const currentTimeSeconds = new Date().getTime() / 1000;
  console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
  if (tokenData && tokenData.exp > currentTimeSeconds) {
    // if (tokenData.exp - currentTimeSeconds > 59*60) {
    return queryWithReportError(args, api, extraOptions);
  }
  if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
    console.log("This api doesn't need token refresh: ", (args as any).url)
    return queryWithReportError(args, api, extraOptions);
  }
  console.log("token is expired!!!!!! start refresh...")
  await requestRefreshToken(api.dispatch, tokenData.userid, tokenRes.refreshToken);
  return queryWithReportError(args, api, extraOptions);
};

const requestRefreshToken = async (dispatch: ThunkDispatch<any, any, any>, userId: string, refreshToken: string | null | undefined) => {
  const response = await signInRefreshToken(userId, refreshToken || "");
  if (response && checkResponse(response)) {
    const { token, refresh_token } = response.data?.data;
    // storeAccessToken(token);
    dispatch(setToken(token));
    dispatch(setRefreshToken(refresh_token));
    console.log("refresh token success save new token : ", token)
  } else {
    console.log("refresh token failed: user id is " + userId)
    // if (process.env.REACT_APP_ENV === 'prod') {
    dispatch(setIdentity(undefined))
    dispatch(clearIdentity())
    dispatch(clearToken())
    dispatch(clearRefreshToken())

    // } else {
    // dispatch(showLogoutDialog())
    // }
  }
}
const queryWithReportError = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const res = await baseQuery(args, api, extraOptions);
  // if (res.error || !res.data || res.meta?.response?.status !== 200) {
  //   console.log("api error == "+JSON.stringify(res))
  //   const errorParam: ErrorReportEntity = {
  //     error_code: res.meta?.response?.status || (res.data as any)?.result?.result_code,
  //     error_message: res.error ? JSON.stringify(res.error) : (res.data as any)?.result?.message,
  //     modules: window.location.pathname,
  //     request_url: (args as any).url,
  //     request_action: 'api',
  //     request_params: (args as any).body ? JSON.stringify((args as any).body) : '',
  //   }
  //   reportError(errorParam);
  // }

  if (res.meta?.response?.status === 200 && res.data && (res.data as any).result?.result_code === 401) {
    console.log("api return 401 token expired, begin to refresh token...")
    const { tokenRes } = api.getState() as RootState;
    if (tokenRes && tokenRes.token) {
      const tokenData = decodeJWT(tokenRes.token);
      await requestRefreshToken(api.dispatch, tokenData.userid, tokenRes.refreshToken);
      console.log("api 401 token refresh finished, request again...")
      return await baseQuery(args, api, extraOptions);
    }
  }
  return res;
}

/*
export const reportError = async (entity: ErrorReportEntity): Promise<AxiosResponse<LoginUserResponse> | void> => {
  const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
      const response = await Axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
              'Content-Type': 'application/json',
              'authorization': getStoredAccessToken(),
          },
        }).post<LoginUserResponse>('/tracking', {
          request_type: "create_tracking",
          request_data: {
            error_code: entity.error_code,
            error_message: entity.error_message,
            browser_type: getExploreName(),
            modules: entity.modules,
            request_url: entity.request_url,
            request_action: entity.request_action,
            request_params: entity.request_params,
          }
      });
      return response
  };

  const error = (e: AxiosError): AxiosResponse | void => {
      return e.response;
  };

  return apiWrapper<LoginUserResponse>({
      success,
      error,
  });
};
*/