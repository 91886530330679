import { FC, useState } from "react";
import { MessageCommentEntity } from "../../models/comment";
import { Avatar, Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import NoCommentIcon from '../../images/post/ico-comment.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCommentTimeDesc } from "../../tools/utils/timeUtil";
import { SvgComponent } from "../svgComponent";
import { getNumberDesc } from "../../tools/utils/stringUtil";
import SubCommentListDialog from "./SubCommentListDialog";
import BGReward from '../../images/defaultIcons/bg-reward.png'
import RedPacket from '../../images/defaultIcons/ico-red-packet.png'
import { useSelector } from "../../tools/toolkit/store";
import { useDispatch } from "react-redux";
import { showSignInUpDialog } from "../../service/redux/signinup-slice";
import { useUpdateThumbMutation } from "../../service/api/post-api";
import { MessageTypeEnum, ThumbType } from "../../models/post";
import { AVATAR_DEFAULT } from "src/tools/utils/deviceUtil";

interface Props {
  viewOnly: boolean;
  comments: MessageCommentEntity[];
}

const CommentList: FC<Props> = ({ viewOnly, comments }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { credential } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [openSubCommentDialog, setOpenSubCommentDialog] = useState(false);
  const [viewComment, setViewComment] = useState<MessageCommentEntity | undefined>();

  const [updateThumb] = useUpdateThumbMutation();

  const viewMoreSubComments = (item: MessageCommentEntity) => {
    setOpenSubCommentDialog(true);
    setViewComment(item);
  }

  const handleUpdateThumb = (item: MessageCommentEntity) => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    }
    if (!item) {
      return;
    }

    item.thumb_from_you = !(item.thumb_from_you);
    if (item.thumb_from_you) {
      item.thumbup = item.thumbup + 1;
    } else {
      item.thumbup = (item.thumbup > 0 ? item.thumbup - 1 : 0);
    }

    updateThumb({ add: item.thumb_from_you, message_id: item?.messageid, message_type: MessageTypeEnum.Message, thumbinfo: true, type: ThumbType.Message, url_type: 0 });
  }

  const handleSubCommentSend = () => {
    if (viewComment) {
      viewComment.commentcount++;
    }
  }

  const handleCommentThumbUpdated = (thumbed: boolean) => {
    if (viewComment) {
      viewComment.thumb_from_you = thumbed;
      viewComment.thumbup = thumbed ? viewComment.thumbup + 1 : (viewComment.thumbup > 0 ? viewComment.thumbup - 1 : 0);
    }
  }

  if (comments?.length > 0) {
    return (
      <Box>
        {comments.map((item, index) => (
          <Box
            key={index}
            sx={{ padding: "12px 0", }}
          >
            <Box sx={{
              display: "flex",
            }}>
              <Avatar alt='personal information' src={item.user?.user_avatar || AVATAR_DEFAULT} style={{ width: "32px", height: "32px" }}></Avatar>
              <Box sx={isMobile ? { width: '100%', paddingBottom: '5px' } : { width: '100%' }}>
                <Box style={{ marginLeft: "12px" }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ flexGrow: 1, paddingRight: '15px' }}>
                      <Typography sx={{
                        textAlign: 'left',
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#303030",
                        wordWrap: 'break-word',
                      }}>{item.user?.user_name || item.userid}</Typography>
                      <Box sx={{
                        marginTop: '5px',
                        textAlign: 'left',
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#222222",
                        wordWrap: 'break-word',
                      }}>{item.message_content.description}</Box>

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <SvgComponent iconName={item.thumb_from_you ? "ico-liked-small" : "ico-like-small"} style={{ width: '12px', height: '11px', marginBottom: '3px' }} />
                      {item.thumbup}
                    </Box>

                  </Box>

                  <Typography sx={{
                    marginTop: '6px',
                    fontFamily: 'SF Pro Text',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#999999',
                    textAlign: 'left',
                  }}>
                    {getCommentTimeDesc((item.timestamp * 1000))}
                  </Typography>
                </Box>

                {!viewOnly &&
                  <Box sx={{ marginLeft: "12px", display: 'flex', alignItems: 'center', marginTop: '12px', justifyContent: 'left' }}>
                    <Button
                      variant="text"
                      size="small"
                      sx={{ justifyContent: 'flex-start', textTransform: 'none', color: '#555555', fontSize: '14px', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, }}
                      startIcon={<SvgComponent iconName="ico-comment" style={{ width: '16px', height: '16px' }} />}
                      onClick={() => viewMoreSubComments(item)}
                    >{item.commentcount ? getNumberDesc(item.commentcount) : 0}</Button>
                    <Button
                      variant="text"
                      size="small"
                      sx={{ textTransform: 'none', color: '#555555', fontSize: '14px', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, }}
                      startIcon={<SvgComponent iconName={item.thumb_from_you ? "ico-liked" : "ico-like"} style={{ width: '16px', height: '16px' }} />}
                      onClick={() => handleUpdateThumb(item)}>{item.thumbup ? getNumberDesc(item.thumbup) : 0}</Button>
                  </Box>
                }

                {item.commentcount > 0 &&
                  <Box
                    sx={{
                      margin: '12px',
                      color: '#3A92F9',
                      fontFamily: 'SF Pro Text',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      cursor: 'pointer',
                      width: 'fit-content',
                    }}
                    onClick={() => viewMoreSubComments(item)}
                  >
                    {`view ${item.commentcount} replies`}
                  </Box>
                }

              </Box>
            </Box>
          </Box>
        ))}

        {openSubCommentDialog && viewComment &&
          <SubCommentListDialog
            open={openSubCommentDialog}
            onClickClose={() => { setOpenSubCommentDialog(false); setViewComment(undefined); }}
            comment={viewComment}
            viewOnly={viewOnly}
            onSendSubComment={handleSubCommentSend}
            onThumbUpdate={handleCommentThumbUpdated}
          />
        }
      </Box>
    )
  } else
    return (
      <Box sx={{
        textAlign: "center",
        height: "300px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
      }}>
        <img alt="no-comment" src={NoCommentIcon} style={{ width: "32px", height: "32px" }} />
        <Typography sx={{ color: '#999999', fontSize: '14px', marginTop: '15px' }}>No comment yet</Typography>
      </Box>
    )
}

export default CommentList;