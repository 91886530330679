import { useEffect } from 'react'
import ProgressBar from './ProgressBar'

const LoadingBar: React.FC = () => {
  useEffect(() => {
    ProgressBar.start()
    return () => {
      ProgressBar.done()
    }
  }, [])

  return <></>
}
export default LoadingBar
