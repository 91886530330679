import { createApi } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { MessageTypeEnum } from '../../models/post';
import { baseQueryWithReauth } from '../../tools/toolkit/helper';

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getMessageFromUser: builder.mutation<Result<any>, { messageType: MessageTypeEnum, userId: string, pageSize?: number, startKey?: string, forwardOrder?: boolean }>({
        query: ({ messageType, userId, pageSize, startKey, forwardOrder }) => ({
          url: `getmessagefromuser`,
          method: 'POST',
          body: { 
            message_type: messageType,
            user_id: userId,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            request_to_user: false,
            followeduser: false,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      updateUserProfile: builder.mutation<Result<any>, { user_name: string, personalized_signature: string, user_avatar: string, gender: string, birthday: string }>({
        query: ({ user_name, personalized_signature, user_avatar, gender, birthday }) => ({
          url: `uploaduserprofile`,
          method: 'POST',
          body: { 
            user_name,
            personalized_signature,
            user_avatar,
            gender,
            birthday,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),


      uploadFile: builder.mutation<Result<any>, {
        user_id: string,
        file_name: string
        file_type: string,
        hash: any,
      }>({
        query: ({file_name, file_type, user_id, hash }) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            file_name,
            file_type,
            user_id,
            hash,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      SendVerifyCode: builder.mutation<Result<any>, {
        auth_address: string,
        // verify_code: string
      }>({
        query: ({auth_address }) => ({
          url: `deleteuser`,
          method: 'POST',
          body: {
            auth_address,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      DeleteUser: builder.mutation<Result<any>, {
        verify_code: string
      }>({
        query: ({verify_code }) => ({
          url: `deleteuser`,
          method: 'POST',
          body: {
            verify_code,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { useGetMessageFromUserMutation, useUpdateUserProfileMutation, useUploadFileMutation, useSendVerifyCodeMutation, useDeleteUserMutation,
} = userApi
