import * as crypto from "crypto";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import * as jwt from "jsonwebtoken";
import { useSelector } from "../toolkit/store";
import { Salt } from "../..//models/user";

const genRandom = (length = 32): any => {
  return new Promise((resolve, reject) => {
    crypto.randomBytes(length, (err: any, buf: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(buf.toString("base64"));
      }
    });
  });
};

export interface TokenData {
  userid: string;
  exp: number;
  iat: number;
}

export const decodeJWT = function (token: string): TokenData {
  const decoded = jwt.decode(token, { complete: true });
  return decoded.payload;
};

export default genRandom;

export interface AuthStatus {
  isLogin: boolean;
  // isMember: boolean
}

export interface GetAuthStatus {
  (): AuthStatus;
}

export const getAuthStatus = (): GetAuthStatus => {
  return (): AuthStatus => {
    const result = useSelector((state) => {
      const authStatus: AuthStatus = {
        isLogin: false,
        // isMember: false
      };
      const { credential } = state.app;
      if (credential) {
        authStatus.isLogin = true;
        // if (credential.membership_info?.ending_date && credential.membership_info.ending_date > Date.now()) {
        //   authStatus.isMember = true;
        // }
      }

      return authStatus;
    });
    return result;
  };
};

export interface AuthRole {
  isAdmin: boolean;
  isTeacher: boolean;
  isAgent: boolean;
  isSchoolAdmin: boolean;
}

export interface GetAuthRole {
  (): AuthRole;
}

export function createHash(text: string, secrect: string): string {
  return Base64.stringify(sha256(text + secrect));
}

export function createPasswordHash(pwd: string, salt: Salt): string {
  return createHash(createHash(pwd, salt.client_salt), salt.server_salt);
}

export const ErrorCode = {
  SIGN_IN_USER_ID_NOT_EXIST: 201202,
  SIGN_IN_PASSWORD_INCORRECT: 201118,
  SIGN_IN_TRY_TOO_MANY_TIMES: 201020,
  SIGN_IN_VERIFY_CODE_INCORRECT: 201109,
};
