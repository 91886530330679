import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
  showSignInDialog: boolean,
  showLogoutAlert: boolean,
}

const initState: AuthState = {
  showSignInDialog: false,
  showLogoutAlert: false,
}


const authSlice = createSlice({
  name: 'sign-in-up',
  initialState: initState,
  reducers: {
    showSignInUpDialog(state) {
      state.showSignInDialog = true;
    },
    closeSignInUpDialog(state) {
      state.showSignInDialog = false;
    },
    showLogoutDialog(state) {
      state.showLogoutAlert = true;
    },
    closeLogoutDialog(state) {
      state.showLogoutAlert = false;
    },
  }
})


export const { showSignInUpDialog, closeSignInUpDialog, showLogoutDialog, closeLogoutDialog } = authSlice.actions

export default authSlice.reducer